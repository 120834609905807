/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_dynamodb_all_tables_region": "us-east-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "seeds-staging",
            "region": "us-east-2"
        }
    ],
    "aws_user_files_s3_bucket": "seedsobjectbucket",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:649b2db6-3cb2-4ad3-8343-1db2bf872052",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_REbqrSski",
    "aws_user_pools_web_client_id": "7dp4ckd53lhbmdh7f2goproc25",
    "oauth": {
        "domain": "microgreensmaster5483fc5f-5483fc5f-staging.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://microgreensmaster.com/",
        "redirectSignOut": "https://microgreensmaster.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
