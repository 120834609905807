/* eslint-disable max-len */
/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';

const courseObject = [
  {
    id: 0,
    title: 'Course Introduction',
    description: <div>
      <h3>The Best Methods of Growing</h3>
      <p>This may come as a surprise but, this course will not show you the very best way to grow Microgreens. There are many methods of growing Microgreens and we will cover a few basic methods. You will learn simple practical methods that work well, to gain a certain level confidence in exploring different techniques, soils, seed densities, light cycles and grow methods in general. In most cases growing a tray of Microgreens will cost ~$2.00 or less and they typically take 10 days from seed to harvest, making Microgreens one of the easiest crops to experiment with. Throwing out a tray that cost $2 in supplies is well worth it if you learned from the experience. There is no perfect method and you are strongly encouraged to experiment and find what works for you, but before experimenting please follow the course lectures closely and do exactly what is shown. This will make troubleshooting much easier if you do encounter any issues.</p>
      <h3>Microgreens Terminology</h3>
      <p>There are many terms used when growing Microgreens. Before we get started it would be good to have a good understanding of some of these terms. </p>
      <ul>
        <li>
          <strong>Stacking: </strong>
          When growing Microgreens, stacking is a common term. Most Microgreens benefit greatly by having a weight applied to the seeds during germination. This achieved by placing a tray with a weight right on top of the freshly planted Microgreens. Commonly a weight of some sort is placed in the top tray to apply an even pressure to the seeds in the trays below, I prefer to use what I call the cap tray method. The cap tray is a tray of Microgreens that either grow better when not being stacked or grow just fine without the weight (Amaranth, Arugula, some mixes, most brassicas, and many herbs). The trays remain stacked until most of the seeds have germinated and typically begin to lift the trays above, then they are un-stacked and introduced directly to light or in some cases have a black out dome placed over them for another day or so.
          {' '}
        </li>
        <li>
          <strong>Bottom Watering: </strong>
          Bottom watering is simply watering the Microgreens from below. This is done with two trays, one with holes placed into a tray without holes. The Microgreens are grown in the tray with holes and when it's time to water the tray is lifted and water is placed in the tray without holes. There are reasons to top water and to bottom water depending on the crop. Many Microgreens are very fragile and watering from above will knock them over, ruining the crop. Bottom watering also keeps the Microgreens themselves dry, making it easier to harvest and brush off small seed hulls. Some Microgreens do better if top watered and there are good reasons for this as well. Sunflower is a crop that does especially well if top watered because this allows them to get a brief wash each day and helps to promote them to shed the seed hull.
          {' '}
        </li>
        <li>
          <strong>Soaking: </strong>
          Soaking quite simply refers to placing seeds in water for a set amount of time. Most seeds do not need to be soaked and will not benefit from doing so while others will perform better if soaked. Peas are a seed that performs better when soaked, germination rates are more consistent and growth is a little faster. The amount of time to soak seeds varies per crop, peas do great with a 3 to 6 hour soak while Sunflower is typically soaked for a couple days with rinse cycles every 8 - 12 hours. Some growers will soak there seeds in a mild solution of H2O2 to sterilize them and prevent mold growth. This can be helpful even when purchasing quality seeds but is not a necessary step if the seeds are coming from a reliable source. Usually I avoid soaking my seeds even if I know there are benefits simply because it is an extra step and the planning required. Some seeds, peas especially will be ruined if soaked for too long, so it is important that your schedule will allow for you to tend to them in time.
        </li>
        <li>
          <strong>True Leaves: </strong>
          Microgreens are commonly thought of as being harvested before the first true leaves show. The first leaves you see are called the cotyledon leaves, these are shed once a plant grows larger. The cotyledon leaves of a Microgreen are super tender, flavorful and pack a nutritional punch. The first true leaves though, are where the beauty is observed with many Microgreens. Mustard's, Kale and Cilantro Microgreens have beautiful first true leaves and the flavor is not negatively impacted by allowing them to grow a little. A Microgreen that really needs to be harvested before the first true leaves grow much is Sunflower, once the true leaves show on Sunflower the Microgreens quickly turn bitter and you loses that nice nutty flavor they are loved for.
        </li>
      </ul>
      <h3>   </h3>
    </div>,
    thumbnail: <img alt="course_1" width="100%" height="auto" src="https://img.youtube.com/vi/87iXkD3mRzo/mqdefault.jpg" />,
    video: <iframe className="courseVideo" title="course_1" src="https://www.youtube.com/embed/87iXkD3mRzo" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />,
  },
  {
    id: 1,
    title: 'The Grow Area',
    description: <div>
      <h1>The Microgreens Grow Area</h1>
      <p>Whether you will be growing on a window sill, rack in your living room or creating a dedicated grow room, there are many things to consider when getting started. Let's take some time and go over all the different aspects to consider.</p>
      <h3>Flooring</h3>
      <p>Growing Microgreens can be quite messy. The planting process, seed hulls (Especially sunflower) falling off, watering and harvesting are daily events that will make a mess! For this reason it is desired to have a floor that is easy to clean. There are many choices of flooring that will work just fine, though some are better than others. The worst flooring you can use would be carpet, for obvious reasons. The best type of flooring is linoleum due to being a solid surface that is easy to clean. Ceramic tile, wood and laminate will work great as well, just be careful to quickly clean up and spills before they soak into any crevices in the flooring. If you absolutely must grow on carpet, you could lay down a tarp or thick plastic sheeting.</p>
      <h3>Walls and Ceiling</h3>
      <p>You will notice that my grow room has a shiny white plastic on the walls and ceiling. This plastic is called Panda plastic because it is white on one side and black on the other. Panda plastic has many uses. The main use for it is to reflect light with the white side and prevent light from going through it due to being black on the other. In my case I love it as a wall covering for its reflective qualities and cleanliness. Using a reflective wall covering will help utilize your grow lights by providing a bright environment. Other popular wall coverings will have a foil like covering due to its reflective nature such as Mylar. Walls painted in a semi gloss paint are also perfectly fine as is and wall coating that can be easily cleaned.</p>
      <h3>Racks</h3>
      <p>When picking out a grow rack there are a few key quality's to keep in mind. </p>
      <ul>
        <li>Shelf spacing should be about 12" to give room for the lights to hang and allow for taller Microgreens like peas to grow.</li>
        <li>Microgreens are typically grown in trays that are called 1020's because they are roughly 10" wide and 20" deep. Because of this racks should be a minimum of 18" deep (common) while 24" is preferred. They should be wide enough to utilize as much of the shelf space as possible. a 36" wide rack will typically allow 3 1020 trays to be lined up per shelf. The racks I use are plastic HDX brand from Home Depot, they are 36" wide by 24" deep and the shelves are 15" apart. </li>
        <li>It is also recommended that racks, like everything be easy to clean. If building racks out of wood they should be painted to prevent mold/mildew and be easy to clean.</li>
        <li>Racks should be easy to move so you can clean under them frequently.</li>
      </ul>
      <h3>Utility Sink</h3>
      <p>A utility sink is a great thing to have for may reasons.Washing trays outside in a New England winter (I did this my first year) is no fun at all!  Having a sink you can wash trays in and sterilize various tools in is great to have and makes keeping everything including your hands clean so much easier. </p>
      <h3>Air Circulation</h3>
      <p>Stagnant air is one of Microgreens worst enemies. Air needs to be constantly flowing around Microgreens to keep them dry to prevent fungus and to promote evaporation from the soil to prevent mold. Fans should be used 24/7 to create air flow even when lights are off. You can use any kind of fan really, you can see I am using a simple oscillating fan in my grow room while in my green house I use multiple box fans.</p>
      <h3>Dehumidifier</h3>
      <p>A semi optional tool of the grow room is a dehumidifier. By far one of the best investments I ever made is my dehumidifier. I like to keep my grow room at or below 50% humidity. This makes harvesting a breeze and helps to allow seed hulls to fall off of the dry leaves. In the winter time in New England humidity is not a problem, though in spring fall and on rainy days it can be a real problem.My dehumidifier is the main reason why I actually prefer to grow indoors rather than in the green house. When it is time to harvest, for whatever reason, it seems to rain. If it is raining outside and you can't control the humidity the Microgreens will be damp, no amount of fans blowing humid air at them will dry them and you never want to package damp Microgreens, more on that later.</p>
      <h3>Refrigeration</h3>
      <p>Everyone growing Microgreens whether for personal use or for clients will need a way to keep them cool once harvested. For small amounts you can absolutely use your personal refrigerator, even when just starting out growing and selling. But if you do use your own fridge you will want to make sure it is very clean. There should not be meat anywhere near the Microgreens and leftovers should be kept to a minimum. It is highly recommended that you purchase a small refrigerator to use dedicated to your Microgreens. The refrigerator is also especially useful to store hydrogen peroxide as it loses potency if in light. If your business outgrows your refrigerator you can buy a walk in cooler or build your own. There are ways to use a air conditioner coupled with a control device like a Cool Bot to make your own walk in cooler. A huge advantage to a walk in cooler is you can put full trays in it to stop there growth if they are ready before you are ready to harvest them.</p>
      <h3>A Bench</h3>
      <p>Every grow room needs a bench to plant and harvest on. I use a plastic folding bench so I can put it away when not in use to maximize my limited space. Benches should be easy to clean and sanitize. I use the same bench for planting and harvesting but I sterilize it with bleach before harvesting every time.</p>
      <h3>Basic Storage</h3>
      <p>There are a bunch of items used to grow and sell Microgreens and you will need somewhere to keep everything. Here is a list of some of the things you will need to have readily available.</p>
      <ul>
        <li>Paper towels, well for all sorts of stuff.</li>
        <li>A printer</li>
        <li>Labels</li>
        <li>Seeds</li>
        <li>Soil</li>
        <li>A scale</li>
        <li>Scissors, knife or other harvesting device</li>
        <li>Date stamp</li>
        <li>Packaging</li>
        <li>Spray bottles</li>
        <li>Bleach and other cleaning supplies</li>
      </ul>
      <h3>Office Area</h3>
      <p>Optional though extremely helpful in any business is an area to work on a computer. Making labels, writing to customers, creating a website and promoting your business on social media are all things you will want to do at a desk if possible. You will also want to track all of your business metrics (There is a section on just this topic) so you know what you spent on seeds, soil and supplies Vs what you made in sales so you can calculate profits and track growth.</p>
    </div>,
    thumbnail: <img alt="course_2" width="100%" height="auto" src="https://img.youtube.com/vi/bpXCcyck_yM/mqdefault.jpg" />,
    video: <iframe title="course_2" className="courseVideo" src="https://www.youtube.com/embed/bpXCcyck_yM" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />,
  },
  {
    id: 2,
    title: 'Our 1st microgreens',
    description: <div>
      <h1>Growing Our First Trays</h1>
      <p>To start off we will be growing some of the easiest to grow Microgreens in the kit. Growing these will not require any previous experience, you will learn a lot from this hands on activity. Let's jump right in!</p>
      <h3>Preparing the Trays</h3>
      <p>None of the trays in the kit have holes in them (At least not in mine), we will need trays with and without holes if we want optimal results. Find a way to put holes in three of the trays. I did this with a drill and ~1/4" bit, you could use other methods such as a soldering iron or even just running a screw through them.The holes do not need to be perfect as they are only there to allow water from the bottom try to seep into the tray with holes. Place each of the trays with holes in it into a tray without holes.</p>
      <h3>The Soil</h3>
      <p>The soil in this kit is great for growing Microgreens, it is nice and fluffy, consistent and has a good amount of perlite in it to retain moisture. Spread into each tray 1/2" to 3/4" of soil and lightly pack it down by hand, later on you can make a simple soil press from a piece of wood if you would like. </p>
      <h3>The Water</h3>
      <p>The kit comes with a PH test kit and now would be a good time to check your water. It is not recommended that you use un filtered tap water (In my case I have a dug well and my water is great) though you are welcome to try. Follow the directions on the PH kit if you need to adjust your PH. You can buy distilled water if you would like and you may need to adjust the PH for that as well. Water the 3 trays heavily from above, the soil should be good and saturated. Any puddling should be quickly absorbed and the soil may drain a little into the tray without holes below.</p>
      <h3>Planting The Seeds</h3>
      <p>We will be growing some very easy to grow Microgreens, each of these should become varieties that you regularly grow due to their ease of growth, flavor, colors and profitability.</p>
      <p>
        <strong>Radish:</strong>
        {' '}
        Easily my favorite Microgreen to grow, radish comes in many different colors. The radish in the kits is Purple Triton Purple (Though kits may vary). This radish Microgreen is beautiful with its purple stem and green leaves. Evenly spread 3 tablespoons (34 - 38 grams) of the radish seed over the damp soil. Avoid only large piles, trying to keep the seed as even as possible over the entire tray. Mist the radish seed with water to ensure the entire seed is damp.
      </p>
      <p>
        <strong>Tatsoi Mustard: </strong>
        Mustard's are also very easy to grow and come in many different colors and flavors. Some Mustard's can be very spicy like the Spicy Oriental and Wasabi variety's or very mild like Tatsoi. Mustard seeds are generally quite small, it is important to resist the urge to plant it too densely. Sparsely planing Mustard allows the sprout room to grow, allowing for the first true leaves to form which I find desirable with Mustard's. Spread just 1.5 tablespoons (18 - 22 grams) of Mustard seed evenly over the damp soil of a tray. Mist the seeds lightly using the spray bottle in the kit.
      </p>
      <p>
        <strong>Basic Salad Mix: </strong>
        Mixes can be made by planting several varieties separately and mixing them after harvest or many varieties can be grown in a single tray. The kit comes with a basic salad mix, a mix I grow regularly. The Basic Salad mix contains Broccoli, Kale, Kohlrabi, Arugula and Cabbage. These varieties grow at very similar rates with the Arugula being the fastest to sprout. Spread 2 tablespoons of the mix over the damp soil and mist lightly with water. The more evenly you distribute the seeds the better, it is important to avoid clumping.
      </p>
      <h3>Stacking The Trays</h3>
      <p>Place the tray of Mustard directly onto the tray of Radish, then place the Basic Salad Mix on top of the Mustard. Place an empty tray upside down on top of the Basic Salad mix to create a humidity dome. Any microgreen can be grown without stacking though some are less sensitive to stacking than others. Leave the trays stacked for 3  - 4 days checking on them each day to see how they are doing. The Arugula in the basic salad mix will be the first seeds to sprout after just one day. Keep the trays stacked until the majority of the seeds have sprouted and the Mustard and Radish are lifting the trays above them.</p>
      <h3>Introducing to Light</h3>
      <p>On day 3 or 4 un-stack the trays and place them under your lights. The lights can be very close to the Microgreens or as far away as mine ("15). Using a timer is highly recommended to control when the lights come on and off. Lights can be left on 24 hours a day though maximum effectiveness will be found at about 18 hours on 6 off. </p>
      <h3>Watering</h3>
      <p>The Microgreens will not need to be watered while they are stacked and typically don't need water until they have been under the lights for a full day. After placing the trays under the lights, carefully lift the edge of each tray to get a sense for how heavy they are, over time you will quickly be able to tell if a tray is dry by feeling how much lighter it is. The radish will be the first to need water and may need to be watered more frequently than the other 2 trays of Microgreens. To water the Microgreens lift the tray with holes up and add water to the tray without holes. I like to water fairly heavily saturating the tray so that I only have to water once a day, though you don't want to completely drown out the Microgreens. After you get a little experience you will know exactly how much water to add. You can not be told how much water to add as nobody knows how dry or damp your tray is, this will be for you to learn as you go. Signs of too little water will be the Microgreens falling over and wilting, to much water can cause root rot and mold issues.</p>
      <h3>Conclusion</h3>
      <p>After 7 to 10 days from seed the Microgreens will be ready to harvest. The radish will be the first to be ready, though they can wait another couple days until the other 2 are ready. For the Mustard's I really prefer to wait until most of the sprouts have the first true leaves showing, however it is not required. I hope you found this section enjoyable, educational and delicious!  </p>
    </div>,
    thumbnail: <img alt="course_3" width="100%" height="auto" src="https://img.youtube.com/vi/yUo4t9W1weg/mqdefault.jpg" />,
    video: <iframe title="course_3" className="courseVideo" src="https://www.youtube.com/embed/yUo4t9W1weg" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />,
  },
  {
    id: 3,
    title: 'Harvesting microgreens',
    description: <div>
      <h1><strong>The First Harvest</strong></h1>
      <p>
        <strong>Congratulations! </strong>
        If you made it this far, I hope it is because you have successfully grown three trays of Microgreens. This might be the very first time you have ever grown Microgreens, or maybe you have done this many times before but are trying to narrow in your skills by following the course. In either case, you should have a nice tray of Mustard, Radish and a basic salad mix ready to harvest.
      </p>
      <h3>When To Harvest</h3>
      <p>When to harvest your Microgreens will depend on the crop, client and personal preference. For this section I will explain when the 3 trays we grew are ideal for harvesting, other crops will be covered in following sections.</p>
      <p>
        <strong>Radish: </strong>
        Radish are ready for harvest when they have all sprouted and are of similar size (Typically 2" tall). The leaves are full and enlarged thought he first true leaves are not yet showing (Radish greens are still good as a baby green, so growing a little longer will not ruin the Microgreen but they will begin to loose their tenderness and the color also will fade). Radish does not have to be harvested at a given point and can usually go a day or so beyond when you think they are best.
      </p>
      <p>
        <strong>Mustard: </strong>
        Every Mustard I have grown gets better with time, to a limit. The first true leaves of Mustard and be very beautiful and increase the value of the crop. Though if grown for too long they may begin to yellow from being crowded in the tray and lacking nutrients. The less dense you plant Mustard the longer you can grow it for. I like to plant Mustard knowing I can harvest it in 10 days or keep it around for 3 weeks if I don't need it at the time. Mustard needs a lot of air flow to minimize the chance of damp sprouts growing under the canopy of the larger greens causing rot.
      </p>
      <p>
        <strong>Basic Salad Mix: </strong>
        The varieties of the Basic Salad Mix will sprout at different times. The Arugula will be first, sprouting after just one day and the Cabbage may take 3 days. I find it takes about 10 days for all the varieties in the mix to have reached a reasonable potential to harvest. Like the Mustard, the mix needs a lot of air circulation and can be grown longer if planted less densely.
      </p>
      <h3>Watering</h3>
      <p>Heavily water Microgreens the day before harvest, early in the day if possible. Let them go a full 24 hours or more without watering before harvesting. This will help greatly in preventing harvesting damp Microgreens. Dampness destroys shelf life causing the Microgreens to rot quickly. If you find the Microgreens to be damp when harvesting, place them in a bowl or in the packaging and then in a refrigerator for about an hour. The refrigerator will pull out the moisture and you can then seal up the packaging. Be careful not to leave them open in the refrigerator for too long or they will wilt (Its best to just ensure they are dry before harvesting).</p>
      <h3>Prepping</h3>
      <p>Before harvesting your beautiful Microgreens, lets take a moment to go over some basic sanitary practices. Bleach is a great way of sanitizing anything that will come in contact with the Microgreens. I use a spay bottle with 50% household bleach like Clorox to 50% water. Using the spray bottle, wash down the bench or table you will be harvesting on. Spray your scissor or other harvesting device and rinse it thoroughly. Wash your hands either with the bleach or a anti bacterial soap (My skin is not sensitive to bleach but your might be). Clean your scale and anything else that will come in contact with the Microgreens or the packaging. Keep some paper towels or a clean cloth nearby that you will use to periodically wipe of your cutting utensil, especially if they accidentally come in contact with soil. Use of disposable gloves is recommended when handling the Microgreens. </p>
      <h3>Cutting</h3>
      <p>There are several ways you can harvest your Microgreens. Personally I use scissors as you can see in the video, you can also use a very sharp knife or mechanical means of cutting the Microgreens.. The trays you received in the course are considered deep trays at right around 2 - 2.5" deep. We only used 1/2" to 3/4" of soil so our Microgreens are quite a bit below the top of the tray. You will need to angle your scissors down into a corner of the tray to get started, attempting to harvest about 1/4" above the soil line. Once you have cleared a little area of the Microgreens out cutting will become much easier.</p>
      <p>When cutting, hold onto the Microgreens towards the top with one hand while cutting with the other. Once you have cut off a  decent sized bunch, gently brush the stems to encourage any small Microgreens or seeds to fall out of the bunch. Inspect the Microgreens as you harvest, discarding anything that isn't ideal ( Smaller greens, seed hulls, wilted greens or anything you wouldn't be proud to present to a client).</p>
      <p>Place the Microgreens in the package of your choice, we will cover in further detail the way I like to package and label in another section, though you will see some of this in the video of this lecture. Orient the Microgreens in a way that will show off the crop the best in the package. I like to place them in a container in one direction for one bunch then reverse the direction with the next bunch. This keeps the leaves looking full on both side of the package while showing off the colorful stems in the middle. Explore with packaging and find your own way to best showcase your product.</p>
      <h3>Weighing</h3>
      <p>How you weigh and then market the Microgreens is very important to the to your business. Not only is it illegal to sell produce mislabeled for weight in most states, it also can harm your reputation. Always remember that a good reputation is difficult and time consuming to build but takes very little time to destroy. It is recommended to use a certified scale, some farmers markets require the use of such a scale. The scale I use in the video is not certified thought I do periodically check it with calibrated weights to ensure it is accurate. Always use an empty package to zero out your scale before weighing the Microgreens. The weight of the package itself should not be included in the weight of the product it contains. It is always a good idea to package a little more than what you are selling the Microgreens at, in the video my packages weighed 2.6 - 2.15 ounces and are sold as 2 ounce packages. Always ensure you are providing a good value, its good business to do so and doesn't cost you much at all.</p>
      <h3>Conclusion</h3>
      <p>This is most of what you need to know to harvest your first trays and get started. You will find tools and techniques that will work better for you over time with experience. You may choose to wash your Microgreens at some point, (Or be required to do so) this will add several steps to the harvesting process. I hope your first experience growing and harvesting these Microgreens has been both educational and enjoyable.</p>
    </div>,
    thumbnail: <img alt="course_4" width="100%" height="auto" src="https://img.youtube.com/vi/OFUULVuqT6g/mqdefault.jpg" />,
    video: <iframe title="course_4" className="courseVideo" src="https://www.youtube.com/embed/OFUULVuqT6g" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />,
  },
  {
    id: 4,
    title: 'Making labels',
    description: <div>
      <h1><strong>The First Harvest</strong></h1>
      <p>In this short video you will learn how I make my labels. You can make them any way you would like. The important thing to learn is what should be on your label.</p>
    </div>,
    thumbnail: <img alt="course_5" width="100%" height="auto" src="https://img.youtube.com/vi/iSLrPMXKVJQ/mqdefault.jpg" />,
    video: <iframe title="course_5" className="courseVideo" src="https://www.youtube.com/embed/iSLrPMXKVJQ" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />,
  },
  {
    id: 5,
    title: 'Soaking seeds',
    description: <div>
      <h1>Soaking and Planting Seeds</h1>
      <p>There are some seeds that perform better if soaked in water before planting. The main benefits of soaking seeds are more even germination and easier shedding of hulls. Soaking can also give you the opportunity to sanitize the seeds if you feel necessary. Some growers soak nearly all their seeds, personally I seed no need to soak the majority of seeds. Below is a list of seeds soaked in this course and how to grow them.</p>
      <h3>What Seeds to Soak</h3>
      <p>
        <strong>Peas: </strong>
        Peas of all kind benefit from a short soak. Soaking for ~4 hours will help the seeds germinate more evenly producing a crop of all the same sized. Be careful to not soak too long as that can cause mushy pea seeds and cause mold issues. Some peas like Thomas Laxton are very sensitive to soaking and shouldn't be soaked for much over 3 hours while Speckled peas can be soak much longer without any issues.
        <strong> </strong>
        Plant 8 - 14 ounces of soaked peas onto 1/2 - 3/4" of soil and cover the seeds with 1/4" of soil. Make sure the soil is very damp and stack the peas for 3 - 4 days, then place directly under light for 3 - 4 days or until the leaves become fluffy.
      </p>
      <p>
        <strong>Sunflower: </strong>
        Sunflower is a robust seed that can be soaked for long periods of time, though long soaks are not necessary. Soaking the seed for ~12 hours, drain, rinse and soak again for another ~12 hours will work great. Some growers will repeat the process for a couple of days until a 1/4" root tail is coming out of each seed, try this if you want but I don't find it beneficial. Use 100 - 200 grams of Black Oil Sunflower seeds per 1020 tray, higher densities may improve the yield but will also result in more shoots with hulls still on them. Plant the seed on 3/4" of soil and stack for 5 - 7 days or until the sunflowers are picking up the trays above, some shoots may be popping out the the edges. Place the unstacked sunflower directly under lights for another 3 - 4 days. Misting the sunflowers while under the lights will help dislodge the seed hull, rubbing your hand vigorously over the sprouts daily helps as well. A few hours before harvesting, provided you have the time gently pick of all the remaining seeds hulls that are easily removed, this will leave yellow spots where the hulls used to be, after just a few hours under light the yellow spots will turn green. Doing your best to remove seed hulls prior to harvesting will save a lot of time when harvesting.
      </p>
      <p>
        <strong>Buckwheat: </strong>
        Buckwheat is very easy to grow, yet another microgreen with seed hull issues. By soaking buckwheat you can nearly eliminate seed hulls from sticking to the shoot. Soak ~4 ounces of seed in water for ~12 hours, rinse and either plant immediately or allow them to sit damp for 12 more hours or so. Plant on 3/4" of very damp soil and stack for 3 - 5 days and place directly under light for 3 - 5 days after unstacking. Buckwheat grows quickly once it sprouts and the seed hulls will actually pop off the shoots, you will see them jumping off! Once fully grown as a microgreen Buckwheat is very easy to harvest and will have very few hulls.
        {' '}
      </p>
      <h3>More Seeds to Soak</h3>
      <p>Along with the seeds we grow in this course, there are plenty of other seeds to experiment with soaking. Sunflower and Peas are the seeds I find the most benefits in soaking, but there are many others to try. Below is a list of commonly soaked seeds (This doesn't mean I recommend it)</p>
      <ul>
        <li><strong>Beets</strong></li>
        <li><strong>Chard</strong></li>
        <li><strong>Cilantro</strong></li>
        <li><strong>Fava Bean</strong></li>
        <li><strong>Wheatgrass (I recommend at least 4 hours)</strong></li>
        <li><strong>Corn</strong></li>
      </ul>
      <p>And More...</p>
    </div>,
    thumbnail: <img alt="course_6" width="100%" height="auto" src="https://img.youtube.com/vi/AnrPkTvs3t8/mqdefault.jpg" />,
    video: <iframe title="course_6" className="courseVideo" src="https://www.youtube.com/embed/AnrPkTvs3t8" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />,
  },
  {
    id: 6,
    title: 'Dealing with hulls',
    description: <div>
      <h1>Dealing with Hulls</h1>
      <p>Harvesting microgreens with hulls is very time consuming. This is why it is important to find growing methods that mitigate the amount of hulls that are left on the shoots. In this lesson we grew sunflower, one of the worst crops when it comes to seed hulls. Following the steps in this course around soaking, stacking and covering seeds in soil will help with lowering the amount of seed hulls left on the shoots but will rarely eliminate them. Many small seed microgreens will also have seed hulls sicking to them like radish and mustard, though most of them just fall off and the ones that don't are not a concern as they are easily consumed.</p>
      <h3>Harvesting Sunflower:</h3>
      <p>As stated earlier, sunflower shoots are a great microgreen for their nutty flavor and uniqueness, highly desired by chefs and even children. Though they have one major drawback, they are difficult to grow with few seed hulls. When harvesting sunflower hold them up towards the top of the of the shoots with one hand and cut towards the bottom of the shoots with the other. While still holding the tops of the shoots, gently rub towards the bottom of the stems allowing shorter shoots that have hulls on them to fall out. Then place the shoots on the palm of your hand and remove any hulls or shoots that don't look presentable before placing in a package. You could also harvest the entire tray and separate the hulls after, however I find you do a better job if done while harvesting.</p>
      <h3>Other Seeds with Hulls</h3>
      <p>Sunflower is maybe the worst crop when it comes to seed hulls but it isn't the only one. In this course you will encounter other microgreens with hull issues. Beets, Cilantro and Buckwheat all have hard hulls that will stick to the microgreens. Like the sunflower shoots we have methods that will mitigate the hulls and those are covered in detail, though we will still have some. When it comes to harvesting, follow the same method as the sunflowers to get a clean crop.</p>
    </div>,
    thumbnail: <img alt="course_7" width="100%" height="auto" src="https://img.youtube.com/vi/8m_dPisCF0U/mqdefault.jpg" />,
    video: <iframe title="course_7" className="courseVideo" src="https://www.youtube.com/embed/8m_dPisCF0U" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />,
  },
  {
    id: 7,
    title: 'Advanced microgreens',
    description: <div>
      <h1>Advance Microgreens in Soil</h1>
      <p>Welcome to the challenging section of this course. The microgreens we will be planing in this section are difficult for many reasons, though they are also rewarding. Once you have mastered these 3 crops along with the others previously grown in this course you will be prepared to grow just about any microgreen successfully. While each of these 3 microgreens are very different, they all have very similar growing requirements. I will go over in detail below each of these advanced microgreens, please follow this lesson carefully to get similar results as in the video, then feel free to experiment on your own.</p>
      <h3><strong>Cilantro:</strong></h3>
      <p>Cilantro as a microgreen has a powerful flavor, some feel it has a soapy flavor and don't care for it on its own while others love it. In either case most chefs will gladly buy micro-cilantro. The main qualities that make this a difficult crop are the seed hulls and the long time it takes to sprout.</p>
      <p><strong>Planting:</strong></p>
      <p>Use a tray with holes inside a tray without holes, fill the tray with holes with approximately 3/4" of soil. Water in the soil fairly heavily just like we have with the other microgreens. Evenly spread 70 grams of dry Cilantro seed over the soil. Seed hulls remaining stuck to the sprouts can be mitigated by covering the seeds with a thin layer (&lt; 1/4") of fine soil or coco coir, the finer the better and gently mist the soil. I do not stack my cilantro though you can if you would like, just a empty tray used as a black out dome will work just fine. </p>
      <p><strong>Growing:</strong></p>
      <p>As the seeds begin to sprout (5 - 10 days) they may pick up large sections of soil, use your spray gun set to stream to break up these clumps and recover with the empty tray. Resist the urge to introduce the cilantro sprouts to light until most of the seeds have germinated then place directly under light. As the sprouts grow, soil clumps will dry out and fall to the bottom of the tray. Bottom water daily, you will find Cilantro to be a fairly heavy drinker so check on them often. If you are late to water them and find they have all fallen over, don't panic, just bottom water and they will pick themselves right back up.</p>
      <p><strong>Harvesting:</strong></p>
      <p>The cilantro will be perfect and ready to harvest ~3 weeks from planting, they can be harvested sooner if you wish though I really enjoy the way they look with true leaves and the yield will be higher if you wait. As you are harvesting separate out any underdeveloped greens that have hulls on them, for the developed greens with hulls you can pinch the hull to crack it open and gently remove it. The amount of effort used to remove hulls vs leaving them vs removing the entire green is entirely up to you. Expected harvest amount fro 70 grams of seed in 3 weeks is 6 - 8 ounces.</p>
      <h3><strong>Beets:</strong></h3>
      <p>Bulls Blood Beets are one of the most demanded microgreens out there, its a name that many chefs are familiar with. Some chefs don't even know they are actually Beets and will just request "Bulls Blood". In the kit we have Detroit Dark red Beets, while not as dark red, these are a less expensive substitute to the Bulls Blood. Any red microgreen will be a hit with chefs and not many are a bigger hit that Beets, unfortunately they are also a challenging crop to grow.</p>
      <p><strong>Planting:</strong></p>
      <p>Use a tray with holes inside a tray without holes, fill the tray with holes with approximately 3/4" of soil. Water in the soil f heavily just like we have with the other microgreens. Evenly spread 60 grams of dry Beet seed over the soil. Seed hulls remaining stuck to the sprouts can be mitigated by covering the seeds with a thin layer (&lt; 1/4") of fine soil or coco coir, the finer the better and gently mist the soil. I do not stack my Beets though you can if you would like, just a empty tray used as a black out dome will work just fine.</p>
      <p><strong>Growing:</strong></p>
      <p>As the seeds begin to sprout (3 - 7 days) they may pick up large sections of soil, use your spray gun set to stream to break up these clumps and recover with the empty tray. Resist the urge to introduce the Beet sprouts to light until most of the seeds have germinated then place directly under light. As the sprouts grow, soil clumps will dry out and fall to the bottom of the tray. Bottom water daily or every other day, Beets are not a large consumer of water and watering too heavily can cause the stems to rot, if you can nail down the watering you will be much closer to growing quality Beets. If you are late to water them and find they have all fallen over, don't panic, just bottom water and they will pick themselves right back up (just like the Cilantro). Giving the greens a gentle misting daily can help some of the seed hulls dislodge from the greens.</p>
      <p><strong>Harvesting:</strong></p>
      <p>The Beets will be perfect and ready to harvest ~3 weeks from planting, they can be harvested sooner if you wish though the yield will be higher if you wait. As you are harvesting separate out any underdeveloped greens that have hulls on them, for the developed greens with hulls you can gently pull off the hulls. The amount of effort used to remove hulls vs leaving them vs removing the entire green is entirely up to you. Expected harvest amount fro 60 grams of seed in 3 weeks is 4 - 6 ounces.</p>
      <h3><strong>Basil:</strong></h3>
      <p>Micro-Basil is also a chef favorite and a favorite of general consumers as well. Basil microgreens can be used exactly the same way as full sized Basil. As a microgreen, basil is super tender and packs a powerful Basil flavor. There are many different varieties of Basil and some are red or purple which can increase their value to certain customers.</p>
      <p><strong>Planting:</strong></p>
      <p>Use a tray with holes inside a tray without holes, fill the tray with holes with approximately 3/4" of soil. Water in the soil heavily. Evenly spread 1 tablespoon of dry Basil seed over the soil, do your best to avoid any clumping as Basil forms a gel coat that will cause clumps to stick together making a mess. I do not stack my Basil though you can if you would like by covering with a very thin layer of soil or vermiculite, just a empty tray used as a black out dome will work just fine.</p>
      <p><strong>Growing:</strong></p>
      <p>Basil seeds will begin to sprout in 3 - 5 days. Keep the basil covered and in the dark until the spouts are ~1/2" tall as it is difficult to get them to grow taller once under lights. Once placed under the lights Basil will only need to be watered once every other day, like the Beets they really do not want too much water. Gently misting the basil daily can help remove any seeds that get stuck to the sprouts. The tray will appear to have been planted way too lightly at first but don't worry, they will quickly become dense. </p>
      <p><strong>Harvesting:</strong></p>
      <p>The Basil will be perfect and ready to harvest ~3 weeks from planting, they can be harvested sooner if you wish though the yield will be higher if you wait. Basil is a lot easier to harvest if you let it grow for the full 3 weeks and the true leaves really add to their appearance and total yield. Expected harvest amount from 1 tablespoon of seed in 3 weeks is 4 - 6 ounces.</p>
      <h2>That's all There is to it!</h2>
      <p>I hope you really enjoyed this advanced growing section and that you learned a lot. In this section you were faced with many difficulties. Microgreens with hulls, covering with soil and dealing with it being picked up, keeping them alive for 3 weeks, difficult harvesting. All these difficulties are huge learning experiences and you will be much more prepared to grow just about any microgreen now. Hopefully you learned that you can experiment more and not be concerned if things don't work out, just plant another tray and keep tweaking your methods.</p>
    </div>,
    thumbnail: <img alt="course_8" width="100%" height="auto" src="https://img.youtube.com/vi/vMXGuMpK2Ak/mqdefault.jpg" />,
    video: <iframe title="course_8" className="courseVideo" src="https://www.youtube.com/embed/vMXGuMpK2Ak" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />,
  },
  {
    id: 8,
    title: 'Water only microgreens',
    description: <div>
      <h1><strong>Water only Microgreens</strong></h1>
      <p>This is just to demonstrate what to expect when growing microgreens with water and no nutrients. Yes they will grow, but they will remain very small.</p>
    </div>,
    thumbnail: <img alt="course_9" width="100%" height="auto" src="https://img.youtube.com/vi/YkjNzZOpp6k/mqdefault.jpg" />,
    video: <iframe title="course_9" className="courseVideo" src="https://www.youtube.com/embed/YkjNzZOpp6k" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />,
  },
  {
    id: 9,
    title: 'Growing with nutrients',
    description: <div>
      <h1><strong>Growing with nutrients</strong></h1>
      <p>Here we will use a hydroponic growing method and use a nutrient solution. I would not normally use Miracle Grow for this though it will work just fine, it was only used here to show what a popular "off the shelf" nutient solution can do. When growing hydroponically I use General Hydroponics Maxi Grow, though this also isn't an organic solution.</p>
    </div>,
    thumbnail: <img alt="course_10" width="100%" height="auto" src="https://img.youtube.com/vi/iO3pgLyYZTM/mqdefault.jpg" />,
    video: <iframe title="course_10" className="courseVideo" src="https://www.youtube.com/embed/iO3pgLyYZTM" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />,
  },

];

export default courseObject;
