/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react';
import { withAuthenticator, AmplifySignIn, AmplifySignOut } from '@aws-amplify/ui-react';
import {
  BrowserRouter, Route, Switch,
} from 'react-router-dom';
import { Container, Col, Row } from 'react-bootstrap';
import SeedsMap from './features/seeds/seedsView';
import './App.css';
import Dashboard from './features/Dashboard';
import Calculator from './features/calculator/Calculator';
import NavBar from './features/NavBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import Course from './features/course/Course';
import Blog from './features/blog/BlogPage';
import LeftSideAds from './features/LeftSideAds';
import RightSideAds from './features/RightSideAds';

function App() {
  const [seedImage, setSeedImage] = useState('');
  return (
    <BrowserRouter>
      <Container fluid className="App">
        <Row>
          <Col>
            <Row>
              <Col>
                <br />
                <NavBar />
                <br />
              </Col>
            </Row>
            <Row>
              <LeftSideAds />
              <Col>
                <Switch>
                  <Route path="/" component={Dashboard} exact />
                  <Route path="/calculator" exact>
                    <Calculator
                      seedImage={seedImage}
                      setSeedImage={setSeedImage}
                    />
                  </Route>
                  <Route path="/calc.php" exact>
                    <Calculator
                      seedImage={seedImage}
                      setSeedImage={setSeedImage}
                    />
                  </Route>
                  <Route path="/seeds" component={SeedsMap} exact />
                  <Route path="/course" exact><Course /></Route>
                  <Route path="/blog" exact><Blog /></Route>
                  <Route path="/signin" exact component={withAuthenticator(AmplifySignIn)} />
                  <Route path="/signout" exact><AmplifySignOut /></Route>
                </Switch>
              </Col>
              <RightSideAds />
            </Row>
          </Col>
        </Row>
        <div className="footer" />
      </Container>
    </BrowserRouter>
  );
}

export default App;
