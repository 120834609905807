import React from 'react';
import {
  Col, Row,
} from 'react-bootstrap';

const Footer = () => (
  <Row>
    <Col className="introduction">
      <p className="text2">
        This website is paid for with the use of affiliate links,
        which adds no cost to you. Thank you for your support.
        Feel free to report issues or request features to Corey
        at brabantfarms@gmail.com
      </p>
    </Col>
  </Row>
);

export default Footer;
