/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import { Event } from '../Tracking';
import EdenBrothers from './EdenBrothers.png';
import MixSide from './MixSide.png';

const RightSideAds = () => (
  <Col md="auto" className="sideAds">
    <Row>
      <Col>
        <iframe title="activeGrow" width="120px" height="250" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=brabantfarm00-20&marketplace=amazon&amp;region=US&placement=B07K7XXHH8&asins=B07K7XXHH8&linkId=158fe85c30667ac5a1785386009b4ebd&show_border=false&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff" />
      </Col>
    </Row>
    <Row>
      <Col>
        <a href="https://www.pntrs.com/t/TUJGRkpOTkJGS0lKRUhCRkpNSkpO?sid=microgreensmaster&website=300592&url=https%3A%2F%2Fwww.edenbrothers.com%2Fstore%2Fsearch.html%3Fq%3Dmicrogreens" target="_blank" rel="sponsored"><img src={EdenBrothers} border="0" width="120" height="600" title="Save on Seeds" alt="Save on Seeds" onClick={() => Event('Ads', 'Click', 'Eden Brothers')} /></a>
      </Col>
    </Row>
    <br />
    <Row>
      <Col>
        <Image src={MixSide} width="120Px" />
      </Col>
    </Row>
    <br />
    <Row>
      <Col>
        <iframe title="trays" width="120px" height="240px" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=brabantfarm00-20&marketplace=amazon&amp;region=US&placement=B074RN2X1G&asins=B074RN2X1G&linkId=aa90f38318c0b7ab35e8a30f5cd7e8ae&show_border=false&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff" />
      </Col>
    </Row>
  </Col>
);

export default RightSideAds;
