/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable max-len */
import React from 'react';

const BlogObject = [
  {
    title: 'mustard',
    content:
  <div>
    <h1 className="title">Distinguish Your Plate with Mustard Microgreens</h1>
    <iframe title="mustard" className="blogVideo" src="https://www.youtube.com/embed/b6WpWX6hU5Q" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
    <div className="blogBody">
      <h2 className="text"><strong>They may surprise you...</strong></h2>
      <p className="blogText">Recently I decided to try out several different kinds of mustard microgreens to see what some of the key differences are. During my trials, two things really stood out. One is that mustard can have many different flavors, from very spicy to mild to unique and earthy. The second characteristic that stood out is the different colors. In this article I will share with you my experiences with the mustards I grew, the flavors, the appearance and potential uses.</p>
      <h2 className="text">
        <strong>Tokyo Bekana Mustard</strong>
        {' '}
        <strong>Microgreens</strong>
      </h2>
      <p className="blogText">
        This was one of the most interesting mustards of the trial. With it’s very mild flavor and nice crunch&nbsp;
        <a href="https://www.pntra.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?sid=microgreensmaster&amp;website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fcollections%2Fmicro-greens-planting-seed%2Fproducts%2Fmustard-tokyo-bekana-microgreens-seeds%3Fvariant%3D46592761544" target="_blank" rel="sponsored">Tokyo Bekana</a>
        {' '}
        is a microgreen I will continue to grow. Tasting more like Pak Choi than mustard to me, those who typically don’t care for mustards may still enjoy this.
      </p>
      <p className="blogText">
        Tokyo Bekana was one of the fastest growing mustard microgreens in this trial and produced the most substantial yield. An extremely easy microgreen to grow, I definitely would recommend&nbsp;
        <a href="https://www.pntra.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?sid=microgreensmaster&amp;website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fcollections%2Fmicro-greens-planting-seed%2Fproducts%2Fmustard-tokyo-bekana-microgreens-seeds%3Fvariant%3D46592761544" target="_blank" rel="sponsored">Tokyo Bekana</a>
        {' '}
        to novice microgreen growers.
      </p>
      <p className="blogText">Due to it’s large leaves and substantial growth this mustard is the perfect addition to microgreen mixes or salad blends. The Pak Choi flavor would make this microgreen ideal to use in mild asian cuisines.</p>
      <h2 className="text"><strong>Osaka Purple Mustard Microgreens </strong></h2>
      <p className="blogText">
        A Japanese mustard,&nbsp;
        <a href="https://www.pntra.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?sid=microgreensmaster&amp;website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fcollections%2Fmicro-greens-planting-seed%2Fproducts%2Fmustard-osaka-purple-microgreens-seeds%3Fvariant%3D46592462408" target="_blank" rel="sponsored">Osaka Purple</a>
        {' '}
        had the most unique flavor of all the microgreens grown in this trial. The flavor starts out with earthy tones and the quickly turns to a nice spicy wasabi like flavor mustard microgreens are known for.
      </p>
      <p className="blogText">A fast growing microgreen that can easily be grown by a beginner, Osaka Purple mustard is also excellent as a baby salad green. Use this mustard anywhere you would use wasabi or want to add spicy punch. Because of the unique flavor I enjoyed this microgreen completely on it’s own as well.</p>
      <p className="blogText">Colorful leaves with purple edges Osaka Purple mustard looks fantastic used as an edible garnish. Add these atop or beside any dish to add some spicy and beauty or use them in wraps and sushi.</p>
      <h2 className="text"><strong>Southern Giant Curled Mustard</strong></h2>
      <p className="blogText">
        A classic mustard,&nbsp;
        <a href="https://www.pntra.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?sid=microgreensmaster&amp;website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fcollections%2Fmicro-greens-planting-seed%2Fproducts%2Fmustard-southern-giant-curled-microgreens-seeds%3Fvariant%3D46592663112" target="_blank" rel="sponsored">Southern Giant Curled</a>
        {' '}
        microgreens have a powerful mustard kick. These taste exactly like you would expect from mustard, though even more powerful when grown as a microgreen.
      </p>
      <p className="blogText">Another very easy to grow microgreen that grows nice and tall making harvesting a breeze providing a healthy yield. </p>
      <p className="blogText">Use these in salads, sandwiches and wraps. The are also a great addition to a spicy microgreens mix.</p>
      <h2 className="text"><strong>Red Garnet Mustard Microgreens</strong></h2>
      <p className="blogText">
        A personal favorite of mine,&nbsp;
        <a href="https://www.pntra.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?sid=microgreensmaster&amp;website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fcollections%2Fmicro-greens-planting-seed%2Fproducts%2Fmustard-red-garnet-microgreens-seeds%3Fvariant%3D46592542344" target="_blank" rel="sponsored">Red Garnet Mustard</a>
        {' '}
        microgreens are just beautiful, especially when the first true leaves are allowed to grow a bit. The cotyledons are a dark purple with specks of lime green coming through. The first true leaves are a very bright green offering a wonderful contrast to the cotyledons.
      </p>
      <p className="blogText">These do have the spicy bite you expect from mustard but not as powerful as some of the others. The beauty of the Red Garnet Mustard microgreens makes them an excellent choice for just about any dish, salad or microgreens mix. While very simple to grow it is highly recommended you grow them a little longer to get that first true leaf to really pop.</p>
    </div>
  </div>,
  },
  {
    title: 'flavorful',
    content:
  <div>
    <h1 className="title">Flavorful Microgreens You Will Love</h1>
    <p className="blogText">With so many varieties of microgreens, you may be wondering which ones you will enjoy the most. Many are surprised to find out they typically taste just like the their adult counterparts but sometimes with a more powerful flavor. In this article we will create several flavor categories and discuss which microgreens belong there and why.</p>
    <iframe title="flavorful" loading="lazy" className="blogVideo" src="https://www.youtube.com/embed/SDQnH9A-CBQ" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" />
    <div className="blogBody">
      <h2 className="text"><strong>Spicy Microgreens Category</strong></h2>
      <p className="blogText">You might be surprised to learn that many microgreens are quite spicy! Here are some of my favorite spicy microgreens.</p>
      <h3 className="text">Radish</h3>
      <p className="blogText">
        <a href="https://www.pntra.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fcollections%2Fmicro-greens-planting-seed%2Fproducts%2Fradish-seeds-rambo%3Fvariant%3D17091915087987" target="_blank" rel="sponsored"> Radish Microgreens</a>
        {' '}
        are probably the most well known for its spicy punch. They come in many colors from red to yellow, while some are more spicy than others you can expect them all to have a least some of that nice radish heat.
      </p>
      <h3 className="text">Mustard</h3>
      <p className="blogText">
        While most mustards are spicy, there is a large bandwidth to the amount of heat to expect. A mild mustard like the
        <a href="https://www.pntrs.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fcollections%2Fmicro-greens-planting-seed%2Fproducts%2Fmustard-mizuna-microgreens-seeds%3Fvariant%3D45188669064" target="blank" rel="sponsored">&nbsp;Mizuna</a>
        {' '}
        is great for those who do not care for a spicy mustard. If a bold and spicy mustard is what you are after, give the
        <a href="https://www.pntra.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fcollections%2Fmicro-greens-planting-seed%2Fproducts%2Fmustard-wasabi-organic-microgreens-seeds%3Fvariant%3D468153460330" target="blank" rel="sponsored">&nbsp;Wasabi Mustard</a>
        {' '}
        a try, this is sure to wake up your taste buds.
      </p>
      <h3 className="text">Arugula</h3>
      <p className="blogText">
        A microgreen with more flavor and spice than its adult counterpart,
        <a href="https://www.pntrs.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fcollections%2Fmicro-greens-planting-seed%2Fproducts%2Farugula-slow-bolt-microgreens-seeds%3Fvariant%3D45198169096" target="blank" rel="sponsored">&nbsp;Arugula Microgreens</a>
        {' '}
        have a complex flavor of nuttiness and spice. These are a favorite of mine to add onto sandwiches or even munch them down on their own for a healthy snack.
      </p>
      <h3 className="text">Nasturtium</h3>
      <p className="blogText">
        Known for edible flowers, nasturtiums can be an excellent choice as a microgreen. These can have a very complex flavor of sweet and spicy like the
        <a href="https://www.gopjn.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fcollections%2Fmicro-greens-planting-seed%2Fproducts%2Fnasturtium-seeds-empress-of-india%3Fvariant%3D18441480405107" target="blank" rel="sponsored">&nbsp;Empress of India Nasturtiums</a>
        {' '}
        . The nasturtium leaves have a very unique shape and the stems can have beautiful colors of pinks, purple and yellow.
      </p>
      <h2 className="text"><strong>Herbal Microgreens Category</strong></h2>
      <p className="blogText">Many of the herbs you are familiar with can also be grown as a microgreen. Herbal microgreens can be used the same way you would use fresh herbs or experiment with the amazing, bold flavors as a delicious garnish.</p>
      <h3 className="text">Basil</h3>
      <p className="blogText">
        Typically when people think of basil, they think of the basic
        <a rel="sponsored" href="https://www.pntrac.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fcollections%2Fmicro-greens-planting-seed%2Fproducts%2Fbasil-genovese-microgreens-seeds%3Fvariant%3D45197500168" target="_blank">&nbsp;Genovese Basil</a>
        {' '}
        . Though there are more flavors to try out!
        <a rel="sponsored" href="https://www.pntrs.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fcollections%2Fmicro-greens-planting-seed%2Fproducts%2Fbasil-thai-microgreens-seeds%3Fvariant%3D45197323784" target="_blank">&nbsp;Thai Basil</a>
        {' '}
        has some licorice undertones you may not expect while
        <a rel="sponsored" href="https://www.pntrac.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fcollections%2Fmicro-greens-planting-seed%2Fproducts%2Fbasil-cinnamon-microgreens-seeds%3Fvariant%3D2968645632041" target="_blank">&nbsp;Cinnamon Basil</a>
        {' '}
        has a slightly spicy flavor to it. There are so many great uses for basil, with it’s different flavors you can add it to many different dishes.
      </p>
      <h3 className="text">Cilantro</h3>
      <p className="blogText">
        A love it or hate herb,
        <a rel="sponsored" href="https://www.pjtra.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fcollections%2Fmicro-greens-planting-seed%2Fproducts%2Fcilantro-seeds-leisure-splits-organic-microgreens-seeds%3Fvariant%3D18650986971251" target="_blank">&nbsp;Cilantro Microgreens</a>
        {' '}
        are great with any mexican cuisine. Cilantro can be an acquired taste, though some claim it tastes like soap. Beautiful as a garnish with a fresh bright flavor (If you like it)! I love these to top off a nice bowl of chili.
      </p>
      <h3 className="text">Curled Cress</h3>
      <p className="blogText">
        Until I started to grow microgreens, I had never tried
        <a rel="sponsored" href="https://www.pjatr.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fcollections%2Fmicro-greens-planting-seed%2Fproducts%2Fcress-curled-microgreens-seeds%3Fvariant%3D46591882888" target="_blank">&nbsp;Curled Cress</a>
        . The peppery flavor is quite unique with an almost minty tone to it. Great on sandwiches, and paired with seafood. Cress germinates and grows extremely fast.
      </p>
      <h3 className="text">Dill</h3>
      <p className="blogText">
        It’s no surprise that the flavor of a dill pickle comes from the dill herb!
        <a rel="sponsored" href="https://www.pjatr.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fcollections%2Fmicro-greens-planting-seed%2Fproducts%2Fdill-bouquet-microgreens-seeds%3Fvariant%3D46593464328" target="_blank">&nbsp;Dill Microgreens</a>
        {' '}
        take a long time to germinate and grow but they are an excellent addition to seafood and soups.
      </p>
      <h2 className="text"><strong>Mild Flavored Microgreens</strong></h2>
      <p className="blogText">Brassicas are one of the largest categories of leafy greens. Most of these are similar in flavor separated largely by their looks and nutritional values. Since these are all mild in flavor, this will be a list of popular ones to try out.</p>
      <ul className="blogText">
        <li>
          <a rel="sponsored" href="https://www.pjatr.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fcollections%2Fmicro-greens-planting-seed%2Fproducts%2Fbroccoli-purple-sprouting-organic-microgreens-seeds%3Fvariant%3D47505458120" target="_blank">&nbsp;Broccoli</a>
          {' '}
          A real super food, full of nutrients
        </li>
        <li>
          <a rel="sponsored" href="https://www.gopjn.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fcollections%2Fmicro-greens-planting-seed%2Fproducts%2Fcabbage-mammoth-red-rock-microgreens-seeds%3Fvariant%3D46582365512" target="_blank">&nbsp;Mammoth Red Rock Cabbage</a>
          {' '}
          Dark green leaves with beautiful purple stems
        </li>
        <li>
          <a rel="sponsored" href="https://www.pntra.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fcollections%2Fmicro-greens-planting-seed%2Fproducts%2Fcabbage-pak-choi-white-stem-microgreens-seeds%3Fvariant%3D48172127432" target="_blank">&nbsp;Pak Choi</a>
          {' '}
          A wonderful Asian flavor
        </li>
        <li>
          <a rel="sponsored" href="https://www.pntrac.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fcollections%2Fmicro-greens-planting-seed%2Fproducts%2Fcauliflower-snowball-y-improved-microgreens-seeds%3Fvariant%3D46593121224" target="_blank">&nbsp;Cauliflower</a>
          {' '}
          A microgreen with a crisp crunch.
        </li>
        <li>
          <a rel="sponsored" href="https://www.pntrac.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fcollections%2Fmicro-greens-planting-seed%2Fproducts%2Fkale-red-russian-microgreens-seeds%3Fvariant%3D46592014920" target="_blank">&nbsp;Red Russian Kale</a>
          {' '}
          My favorite kale microgreen for its pink stems and frilled leaves.
        </li>
        <li>
          <a rel="sponsored" href="https://www.pntrac.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fcollections%2Fmicro-greens-planting-seed%2Fproducts%2Fkohlrabi-seeds-purple-vienna-organic-microgreens-seeds%3Fvariant%3D18647457235059" target="_blank">&nbsp;Purple Kohlrabi </a>
          Brilliant purple stems with bright green leaves
        </li>
      </ul>
      <h2 className="text"><strong>Uniquely Flavored Microgreens</strong></h2>
      <p className="blogText">These microgreens don’t really fit in any one category due to the unique flavors they have. This is my favorite category, who doesn’t love unexpected flavors? </p>
      <h3 className="text">Sunflower Shoots</h3>
      <p className="blogText">
        When I first started growing microgreens, sunflower was one that really stood out.
        <a rel="sponsored" href="https://www.pjtra.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fcollections%2Fmicro-greens-planting-seed%2Fproducts%2Fsunflower-sprouting-black-oil-organic-seeds%3Fvariant%3D38494671240" target="_blank">&nbsp;Sunflower Microgreens</a>
        {' '}
        have an interesting nutty flavor. Very crisp and hearty, even kids love munching these down. I wonder who was the first to decide to try sunflowers as a microgreen? I wouldn’t have thought of it on my own. It is very important to harvest these before the first true leaves come in or they become bitter.
        {' '}
      </p>
      <h3 className="text">Borage</h3>
      <p className="blogText">
        Known for it’s pretty little blue flowers,
        <a rel="sponsored" href="https://www.pjtra.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fcollections%2Fmicro-greens-planting-seed%2Fproducts%2Fborage-microgreens-seeds%3Fvariant%3D9684383367283" target="_blank">&nbsp;Borage Microgreens</a>
        {' '}
        taste just like a fresh cucumber. When used in a microgreen salad mix, these are absolutely delicious.
      </p>
      <h3 className="text">Corn Shoots</h3>
      <p className="blogText">
        Grown in the dark
        <a rel="sponsored" href="https://www.pntrac.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fcollections%2Fmicro-greens-planting-seed%2Fproducts%2Fcorn-yellow-popcorn-microgreens-seeds-organic%3Fvariant%3D45702436552" target="_blank">&nbsp;Corn Shoots</a>
        {' '}
        are a bright yellow. This is another love it or hate it microgreen that has a artificial sweetener flavor. Perfect for pairing with desserts!
      </p>
      <h3 className="text">Red Veined Sorrel</h3>
      <p className="blogText">
        A wonderfully pretty microgreen that takes a lot of patience to grow! Very bright leaves with deep red streaks throughout,
        <a rel="sponsored" href="https://www.pjtra.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fcollections%2Fmicro-greens-planting-seed%2Fproducts%2Fsorrel-red-veined-microgreens-seeds%3Fvariant%3D4147236732969" target="_blank">&nbsp;Red Veined Sorrel</a>
        {' '}
        has a pleasant citrus flavor that is quite surprising.
        {' '}
      </p>
      <h3 className="text">Pea Shoots</h3>
      <p className="blogText">
        Usually the first microgreen people try. Everyone who eats a
        <a rel="sponsored" href="https://www.pntrs.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fcollections%2Fmicro-greens-planting-seed%2Fproducts%2Fpea-sprouting-speckled-organic%3Fvariant%3D39470787592" target="_blank">&nbsp;Pea Shoot</a>
        {' '}
        for the first time can’t believe how they taste just like a fresh sweet pea!
      </p>
    </div>
  </div>,
  },
  {
    title: 'radish',
    content:
  <div>
    <h1 className="title">Beautiful, Flavorful Radish Microgreens</h1>
    <p className="text">Radish microgreens are one of the most popular microgreens to grow and there are many reasons for that. </p>
    <ul className="blogText">
      <li>Super easy to grow</li>
      <li>Big flavor for a small green</li>
      <li>Many colors to choose from</li>
      <li>So many uses (Salads, garnish, soups, sandwiches etc…)</li>
    </ul>
    <p className="text">My Recent Radish Microgreens Test</p>
    <p className="blogText">With so many radish microgreen varieties to choose from I recently ran a detailed test where I grew 7 different varieties to compare. Here is a video I made showing the results. All of the microgreens in this experiment were harvested a little early, yields would be much higher if grown for an extra day or two.</p>
    <p className="text"> Judging Radish Microgreens</p>
    <p className="blogText">While harvesting these radish microgreens there were essentially 4 categories I was focused on.</p>
    <ul className="blogText">
      <li>Cost of seed</li>
      <li>Color of the leaves and stems</li>
      <li>Yield per tray</li>
      <li>Flavor</li>
    </ul>
    <p />
    <p className="text">Red Rambo Radish Microgreens</p>
    <p className="blogText">
      All the purple microgreens under performed in yield per tray as compared to the pink stemmed radish microgreens. The
      <a href="https://www.pntra.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fproducts%2Fradish-seeds-red-vulcano-organic-microgreens-seeds%3Fvariant%3D12590428258419" target="blank" rel="sponsored">&nbsp;Vulcano Radish</a>
      {' '}
      had the best yield out of the radish microgreens, however the extra cost just didn’t seem to be worth it as compared to the
      <a href="https://www.pntrac.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fproducts%2Fradish-rambo-seeds%3Fvariant%3D39483221192" target="blank" rel="sponsored">&nbsp;Red Rambo</a>
      {' '}
      which is my choice for a purple radish microgreen. The flavor of the
      <a href="https://www.pntrac.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fproducts%2Fradish-rambo-seeds%3Fvariant%3D39483221192" target="blank" rel="sponsored">&nbsp;Red Rambo</a>
      {' '}
      was not quite as powerful as the
      <a href="https://www.pjtra.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fproducts%2Fradish-sango-purple-organic-seeds%3Fvariant%3D39483267400" target="blank" rel="sponsored">&nbsp;Sango</a>
      {' '}
      however without a side by side comparision I’m not sure anyone would know the difference. Here is the score breakdown for the Rambo radish microgreens.
    </p>
    <ul className="blogText">
      <li>Cost: 8</li>
      <li>Color: 10</li>
      <li>Yield: 6</li>
      <li>Flavor: 7</li>
    </ul>
    <p />
    <p className="text">Red Arrow Radish Microgreens</p>
    <p className="blogText">
      These radish microgreens have a decent pink/reddish stem color. The
      <a href="https://www.pjtra.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fproducts%2Fradish-sprouting-red-arrow-seeds%3Fvariant%3D39110761928" target="blank" rel="sponsored">&nbsp;Red Arrow</a>
      {' '}
      radish microgreens are extremely inexpensive seeds, easy to grow and produce a large yield quickly. These microgreens pack a nice spicy radish punch sure to add flavor to any dish!
      <a href="https://www.pntrs.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fproducts%2Fradish-seeds-china-rose-organic-microgreens-seeds%3Fvariant%3D12620084052083" target="blank" rel="sponsored">&nbsp;China Rose</a>
      {' '}
      microgreens are a very close second to the Red Arrow Radish. Lets take a look at how this microgreen scored.
    </p>
    <ul className="blogText">
      <li>Cost: 10</li>
      <li>Color: 8</li>
      <li>Yield: 8</li>
      <li>Flavor: 9</li>
    </ul>
    <p className="text">Triton Purple Radish Microgreens</p>
    <p className="blogText">
      The
      <a href="https://www.gopjn.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fproducts%2Fradish-sprouting-triton-purple-seeds%3Fvariant%3D39110766024" target="blank" rel="sponsored">&nbsp;Triton Purple</a>
      {' '}
      radish microgreen had an incredible yield per tray performing better than most. These microgreens are fairly unique for having a pretty purple stem and green leaves. As a very inexpensive seed these radish microgreens are comparable to the much more expensive
      <a href="https://www.pntrac.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?website=300592&amp;url=https%3A%2F%2Fwww.trueleafmarket.com%2Fproducts%2Fradish-hong-vit-seeds%3Fvariant%3D39483256072" target="blank" rel="sponsored">&nbsp;Hong Vit</a>
      {' '}
      radish. While the flavor is not terribly powerful these microgreens have a nice amount of substance to them with a thick crunchy stem. How did these microgreens score?
    </p>
    <ul className="blogText">
      <li>Cost: 10</li>
      <li>Color: 8</li>
      <li>Yield: 10</li>
      <li>Flavor: 8</li>
    </ul>
    <p className="text">Conclusion</p>
    <p className="blogText">All of the tested radish microgreens are a good choice for a grower or a consumer. This test was done to show the small differences, helping you decide which of the many radish microgreens you may want to grow. If you found this post useful please let me know by leaving a comment or contacting me at BrabantFarms@gmail.com.</p>
  </div>,
  },
];

export default BlogObject;
