const PlantingList = [
  {
    name: 'Amaranth - Red Garnet - Microgreens Seeds',
    plant: 20,
    production: 142,
    flavor: 'Mild earthy flavor similar to beets.',
    difficulty: 'Advanced',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '14 Days',
    stack: 'No',
    tip: 'Spread seed very evenly, avoid crowding as it will lead to damping off.',
  },
  {
    name: 'Amaranth - Red Garnet (organic) - Microgreens Seeds',
    plant: 20,
    production: 142,
    flavor: 'Mild earthy flavor similar to beets.',
    difficulty: 'Advanced',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '14 Days',
    stack: 'No',
    tip: 'Spread seed very evenly, avoid crowding as it will lead to damping off.',
  },
  {
    name: 'Amaranth (organic) - Microgreens Seeds',
    plant: 20,
    production: 142,
    flavor: 'Mild earthy flavor similar to beets.',
    difficulty: 'Advanced',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '14 Days',
    stack: 'No',
    tip: 'Spread seed very evenly, avoid crowding as it will lead to damping off.',
  },
  {
    name: 'Anise - Microgreens Seeds',
    plant: 20,
    production: 85,
    flavor: 'Strong licorice flavor.',
    difficulty: 'Advanced',
    soak: 'No',
    method: 'Soil preffered',
    harvestTime: '21 Days',
    stack: 'No',
    tip: 'Keep seeds damp until they have sprouted. Keep covered with a black out dome until the microgreens have begun to stretch for light.',
  },
  {
    name: 'Arugula - Slow Bolt - Microgreens Seeds',
    plant: 20,
    production: 200,
    flavor: 'A wonderfully complex peppery flavor with a nutty aftertaste.',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days',
    stack: 'Preferred',
    tip: 'Be careful to not use too much seed. Arugula will grow a nice full canopy and can hold too much moiture under it if planted too heavily.',
  },
  {
    name: 'Basic Salad Mix - Microgreens Seeds',
    plant: 15,
    production: 200,
    flavor: 'Mild flavors of broccoli and cabbage.',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days',
    stack: 'Preferred',
    tip: 'The cabbage seed sprouts slightly slower than the others in this mix, if stacking keep stacked intil the cabbage has established a healthy root.',
  },
  {
    name: 'Basic Salad Mix (Organic) - Microgreens Seeds',
    plant: 15,
    production: 200,
    flavor: 'Mild flavors of broccoli and cabbage.',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days',
    stack: 'Preferred',
    tip: 'The cabbage seed sprouts slightly slower than the others in this mix, if stacking keep stacked intil the cabbage has established a healthy root.',
  },
  {
    name: 'Basil - Cinnamon - Microgreens Seeds',
    plant: 12,
    production: 142,
    flavor: 'Like its name, basil flavor with a cinnamon like spice!',
    difficulty: 'Intermediate',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '14 - 21+ Days, basil can be harvested very small for a extremely tender microgreen or allowed to grow large true leaves for a higher yeild',
    stack: 'No',
    tip: 'Avoid clumping of the seeds as they will stick together. Keep covered with a blackout dome until the sprouts are about 1/2" tall then place under light.',
  },
  {
    name: 'Basil - Dark Opal - Microgreens Seeds',
    plant: 12,
    production: 142,
    flavor: 'A classically flavored basil with a beautiful purple color',
    difficulty: 'Intermediate',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '14 - 21+ Days, basil can be harvested very small for a extremely tender microgreen or allowed to grow large true leaves for a higher yeild',
    stack: 'No',
    tip: 'Avoid clumping of the seeds as they will stick together. Keep covered with a blackout dome until the sprouts are about 1/2" tall then place under light.',
  },
  {
    name: 'Basil - Genovese - Microgreens Seeds',
    plant: 12,
    production: 142,
    flavor: 'Classic basil flavor with mild licorice undertones',
    difficulty: 'Intermediate',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '14 - 21+ Days, basil can be harvested very small for a extremely tender microgreen or allowed to grow large true leaves for a higher yeild',
    stack: 'No',
    tip: 'Avoid clumping of the seeds as they will stick together. Keep covered with a blackout dome until the sprouts are about 1/2" tall then place under light.',
  },
  {
    name: 'Basil - Thai - Microgreens Seeds',
    plant: 12,
    production: 142,
    flavor: 'Classic basil flavor with mild licorice undertones',
    difficulty: 'Intermediate',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '14 - 21+ Days, basil can be harvested very small for a extremely tender microgreen or allowed to grow large true leaves for a higher yeild',
    stack: 'No',
    tip: 'Avoid clumping of the seeds as they will stick together. Keep covered with a blackout dome until the sprouts are about 1/2" tall then place under light.',
  },
  {
    name: 'Basil Seeds - Italian Large Leaf - Microgreens Seeds',
    plant: 12,
    production: 142,
    flavor: 'Classic basil flavor with mild licorice undertones',
    difficulty: 'Intermediate',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '14 - 21+ Days, basil can be harvested very small for a extremely tender microgreen or allowed to grow large true leaves for a higher yeild',
    stack: 'No',
    tip: 'Avoid clumping of the seeds as they will stick together. Keep covered with a blackout dome until the sprouts are about 1/2" tall then place under light.',
  },
  {
    name: 'Basil Seeds - Sweet (Organic) - Microgreens',
    plant: 12,
    production: 142,
    flavor: 'Classic basil flavor with mild licorice undertones',
    difficulty: 'Intermediate',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '14 - 21+ Days, basil can be harvested very small for a extremely tender microgreen or allowed to grow large true leaves for a higher yeild',
    stack: 'No',
    tip: 'Avoid clumping of the seeds as they will stick together. Keep covered with a blackout dome until the sprouts are about 1/2" tall then place under light.',
  },
  {
    name: 'Basil Seeds - Red Rubin - Microgreens Seeds',
    plant: 12,
    production: 142,
    flavor: 'Classic basil flavor with mild licorice undertones',
    difficulty: 'Intermediate',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '14 - 21+ Days, basil can be harvested very small for a extremely tender microgreen or allowed to grow large true leaves for a higher yeild',
    stack: 'No',
    tip: 'Avoid clumping of the seeds as they will stick together. Keep covered with a blackout dome until the sprouts are about 1/2" tall then place under light.',
  },
  {
    name: 'Bean Seeds - Black Garbanzo - Organic - Microgreens',
    plant: 10,
    production: 45,
    flavor: 'Similar to a sweet pea shoot with citrus',
    difficulty: 'Beginner',
    soak: 'Preferred 4 - 6 hours',
    method: 'Soil preferred',
    harvestTime: '1o Days or until deisred size, tenderness and taste has been met.',
    stack: 'Yes',
    tip: 'Cover seeds with a thin layer of soil. Top water as they grow to help clean off any lifted soil',
  },
  {
    name: 'Bean Seeds - Fava (Organic) - Microgreens Seeds',
    plant: 10,
    production: 45,
    flavor: 'A cross between a sweet pea and sunflower shoot',
    difficulty: 'Beginner',
    soak: 'Yes',
    method: 'Soil',
    harvestTime: '10 - 14 Days.',
    stack: 'Yes',
    tip: 'Harvest when the first leaves open up. Fava can easily grow much larger than desired.',
  },
  {
    name: 'Beet - Bulls Blood - Microgreens Seeds',
    plant: 60,
    production: 142,
    flavor: 'Mild beet flavor',
    difficulty: 'Advanced',
    soak: 'Optional',
    method: 'Soil',
    harvestTime: '14+ Days.',
    stack: 'Yes',
    tip: 'Cover the seeds with a thin layer of very fine soil or coco coir. Once most of the seeds have sprouted, cover with a clear humidity dome to help seed hulls separate from the sprouts. Beets are a cluster seed and more than one microgreen will grow per seed',
  },
  {
    name: 'Beet - Bulls Blood (Organic) - Microgreens Seeds',
    plant: 60,
    production: 142,
    flavor: 'Mild beet flavor',
    difficulty: 'Advanced',
    soak: 'Optional',
    method: 'Soil',
    harvestTime: '14+ Days.',
    stack: 'Yes',
    tip: 'Cover the seeds with a thin layer of very fine soil or coco coir. Once most of the seeds have sprouted, cover with a clear humidity dome to help seed hulls separate from the sprouts. Beets are a cluster seed and more than one microgreen will grow per seed',
  },
  {
    name: 'Beet - Detroit Dark Red (Conventional) - Microgreens Seeds',
    plant: 60,
    production: 142,
    flavor: 'Mild beet flavor',
    difficulty: 'Advanced',
    soak: 'Optional',
    method: 'Soil',
    harvestTime: '14+ Days.',
    stack: 'Yes',
    tip: 'Cover the seeds with a thin layer of very fine soil or coco coir. Once most of the seeds have sprouted, cover with a clear humidity dome to help seed hulls separate from the sprouts. Beets are a cluster seed and more than one microgreen will grow per seed',
  },
  {
    name: 'Beet - Detroit Dark Red (Organic) - Microgreens Seeds',
    plant: 60,
    production: 142,
    flavor: 'Mild beet flavor',
    difficulty: 'Advanced',
    soak: 'Optional',
    method: 'Soil',
    harvestTime: '14+ Days.',
    stack: 'Yes',
    tip: 'Cover the seeds with a thin layer of very fine soil or coco coir. Once most of the seeds have sprouted, cover with a clear humidity dome to help seed hulls separate from the sprouts. Beets are a cluster seed and more than one microgreen will grow per seed',
  },
  {
    name: 'Beet - Detroit Mix (Organic) - Microgreens Seeds',
    plant: 60,
    production: 142,
    flavor: 'Mild beet flavor',
    difficulty: 'Advanced',
    soak: 'Optional',
    method: 'Soil',
    harvestTime: '14+ Days.',
    stack: 'Yes',
    tip: 'Cover the seeds with a thin layer of very fine soil or coco coir. Once most of the seeds have sprouted, cover with a clear humidity dome to help seed hulls separate from the sprouts. Beets are a cluster seed and more than one microgreen will grow per seed',
  },
  {
    name: 'Beet - Microgreens Seeds',
    plant: 60,
    production: 142,
    flavor: 'Mild beet flavor',
    difficulty: 'Advanced',
    soak: 'Optional',
    method: 'Soil',
    harvestTime: '14+ Days.',
    stack: 'Yes',
    tip: 'Cover the seeds with a thin layer of very fine soil or coco coir. Once most of the seeds have sprouted, cover with a clear humidity dome to help seed hulls separate from the sprouts. Beets are a cluster seed and more than one microgreen will grow per seed',
  },
  {
    name: 'Borage - Microgreens Seeds',
    plant: 50,
    production: 200,
    flavor: 'A fresh cucumber flavor',
    difficulty: 'Intermediate',
    soak: 'Optional',
    method: 'Soil',
    harvestTime: '10 - 14 Days.',
    stack: 'Yes',
    tip: 'Mist the seedlings to help get the seed hulls separate from the leaves',
  },
  {
    name: 'Broccoli - Calabrese Green Sprouting (Organic) - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Slightly pungent broccoli flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days.',
    stack: 'Yes',
    tip: 'Broccoli can tend to get crowded in the tray and hold moisture. Good airflow and humidity control will help prevent any mushy microgreens from forming in the middle of the tray.',
  },
  {
    name: 'Broccoli - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Slightly pungent broccoli flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days.',
    stack: 'Yes',
    tip: 'Broccoli can tend to get crowded in the tray and hold moisture. Good airflow and humidity control will help prevent any mushy microgreens from forming in the middle of the tray.',
  },
  {
    name: 'Broccoli - Purple Sprouting (Organic) - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Slightly pungent broccoli flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days.',
    stack: 'Yes',
    tip: 'Broccoli can tend to get crowded in the tray and hold moisture. Good airflow and humidity control will help prevent any mushy microgreens from forming in the middle of the tray.',
  },
  {
    name: 'Broccoli - Waltham 29 - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Slightly pungent broccoli flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days.',
    stack: 'Yes',
    tip: 'Broccoli can tend to get crowded in the tray and hold moisture. Good airflow and humidity control will help prevent any mushy microgreens from forming in the middle of the tray.',
  },
  {
    name: 'Brussels Sprouts - Long Island Improved - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Mild brussel sprout flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days.',
    stack: 'Yes',
    tip: 'Brussel sprout microgreens are an excellent addition to any microgreen mix.',
  },
  {
    name: 'Buckwheat (organic) - Microgreens Seeds',
    plant: 100,
    production: 340,
    flavor: 'Fresh peppery flavor',
    difficulty: 'Intermediate',
    soak: 'Yes',
    method: 'Soil or Hydroponic',
    harvestTime: '10 - 14 Days.',
    stack: 'Yes',
    tip: 'Mist the buckweet sprouts daily to help remove the seed hulls from the leaves.',
  },
  {
    name: 'Cabbage - Golden Acre - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Mild cabbage flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days.',
    stack: 'Yes',
    tip: 'Cabbage can tend to get crowded in the tray and hold moisture. Good airflow and humidity control will help prevent any mushy microgreens from forming in the middle of the tray.',
  },
  {
    name: 'Cabbage - Mammoth Red Rock - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Mild cabbage flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days.',
    stack: 'Yes',
    tip: 'Cabbage can tend to get crowded in the tray and hold moisture. Good airflow and humidity control will help prevent any mushy microgreens from forming in the middle of the tray.',
  },
  {
    name: 'Cabbage - Pak Choi White Stem - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Wonderful oriental cabbage flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days.',
    stack: 'Yes',
    tip: 'Cabbage can tend to get crowded in the tray and hold moisture. Good airflow and humidity control will help prevent any mushy microgreens from forming in the middle of the tray.',
  },
  {
    name: 'Cabbage - Red Acre - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Mild cabbage flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days.',
    stack: 'Yes',
    tip: 'Cabbage can tend to get crowded in the tray and hold moisture. Good airflow and humidity control will help prevent any mushy microgreens from forming in the middle of the tray.',
  },
  {
    name: 'Cabbage Seeds - Red Acre (Organic) - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Mild cabbage flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days.',
    stack: 'Yes',
    tip: 'Cabbage can tend to get crowded in the tray and hold moisture. Good airflow and humidity control will help prevent any mushy microgreens from forming in the middle of the tray.',
  },
  {
    name: 'Carrot - Microgreens Seeds',
    plant: 10,
    production: 114,
    flavor: 'Sweet carrot flavor',
    difficulty: 'Intermediate',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '10 - 14+ Days.',
    stack: 'Yes',
    tip: 'Once the carrot seeds have sprouted, cover with a clear humidity dome to help remove seed hulls. Once harvested any seed hulls remaining can easily be consumed. Carrot microgreens are an excellent addition to a microgreens mix.',
  },
  {
    name: 'Cauliflower - Snowball Y Improved - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Mild cabbage flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days.',
    stack: 'Yes',
    tip: 'Cauliflower can tend to get crowded in the tray and hold moisture. Good airflow and humidity control will help prevent any mushy microgreens from forming in the middle of the tray.',
  },
  {
    name: 'Celery - Utah 52-70 - Microgreens Seeds',
    plant: 10,
    production: 114,
    flavor: 'Strong celery flavor',
    difficulty: 'Advanced',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '21 - 28 Days.',
    stack: 'No, use a blackout dome',
    tip: 'Keep the seeds damp by misting ever couple days while germinating',
  },
  {
    name: 'Chervil - Curled - Microgreens Seeds',
    plant: 10,
    production: 142,
    flavor: 'Mild parsley like flavor',
    difficulty: 'Intermediate',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '14 Days.',
    stack: 'Yes',
    tip: 'Once the seeds have germinated and begun to root, cover the sprouts with a clear humidity dome to keep the damp.',
  },
  {
    name: 'Chia (Organic) - Microgreens Seeds',
    plant: 10,
    production: 85,
    flavor: 'Slightly bitter',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days.',
    stack: 'No',
    tip: 'Keep seeds moist during germination',
  },
  {
    name: 'Chinese Mahogany Seeds - Microgreens',
    plant: 60,
    production: 85,
    flavor: 'Unigue garlic flavor with nutty hints',
    difficulty: 'Advanced',
    soak: 'Yes',
    method: 'Soil',
    harvestTime: '21+ Days.',
    stack: 'Yes',
    tip: 'These can take a long time to germinate and grow. Once grown these are one of the few cut and come again microgreens.',
  },
  {
    name: 'Chives - Microgreens Seeds',
    plant: 30,
    production: 85,
    flavor: 'Onion flavor',
    difficulty: 'Advanced',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '21+ Days.',
    stack: 'Yes',
    tip: 'Chives are a slow grower that can take patience to get just right.',
  },
  {
    name: 'Chrysanthemum Seeds - Broad Leaf, Edible - Microgreens',
    plant: 10,
    production: 114,
    flavor: 'Similar to lettuce with a little more bitterness.',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '14 - 21 Days.',
    stack: 'Yes',
    tip: 'The seeds are very small, be carful to not overseed.',
  },
  {
    name: 'Cilantro - Slow Bolt - Microgreens Seeds',
    plant: 30,
    production: 170,
    flavor: 'Strong cilantro flavor',
    difficulty: 'Advanced',
    soak: 'Optional',
    method: 'Soil preferred',
    harvestTime: '14 - 21 Days.',
    stack: 'Yes',
    tip: 'Be patient, cilantro takes a long time to germinate. Cover the seeds with a thin layer of fine soil or coco coir. Whole seeds will sprout 2 microgreens per seed. Once germinated cover with a clear humidity dome to keep the seed hulls moist so the fall off.',
  },
  {
    name: 'Cilantro Seeds - Leisure Splits - Microgreens Seeds',
    plant: 30,
    production: 170,
    flavor: 'Strong cilantro flavor',
    difficulty: 'Advanced',
    soak: 'Optional',
    method: 'Soil preferred',
    harvestTime: '14 - 21 Days.',
    stack: 'Yes',
    tip: 'Be patient, cilantro takes a long time to germinate. Cover the seeds with a thin layer of fine soil or coco coir. Once germinated cover with a clear humidity dome to keep the seed hulls moist so the fall off.',
  },
  {
    name: 'Cilantro Seeds - Leisure Splits (Organic) - Microgreens Seeds',
    plant: 30,
    production: 170,
    flavor: 'Strong cilantro flavor',
    difficulty: 'Advanced',
    soak: 'Optional',
    method: 'Soil preferred',
    harvestTime: '14 - 21 Days.',
    stack: 'Yes',
    tip: 'Be patient, cilantro takes a long time to germinate. Cover the seeds with a thin layer of fine soil or coco coir. Once germinated cover with a clear humidity dome to keep the seed hulls moist so the fall off.',
  },
  {
    name: 'Cilantro Seeds - Slow Bolt (organic) - Microgreens Seeds',
    plant: 30,
    production: 170,
    flavor: 'Strong cilantro flavor',
    difficulty: 'Advanced',
    soak: 'Optional',
    method: 'Soil preferred',
    harvestTime: '14 - 21 Days.',
    stack: 'Yes',
    tip: 'Be patient, cilantro takes a long time to germinate. Cover the seeds with a thin layer of fine soil or coco coir. Whole seeds will sprout 2 microgreens per seed. Once germinated cover with a clear humidity dome to keep the seed hulls moist so the fall off.',
  },
  {
    name: 'Clover - Red (organic) - Microgreens Seeds',
    plant: 10,
    production: 114,
    flavor: 'Mild flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days.',
    stack: 'Yes',
    tip: 'Clover grows very short, place under a blackout dome to get them to stretch a little taller before introducing to light.',
  },
  {
    name: 'Collards - Vates - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Like the adult counterpart with more intensity.',
    difficulty: 'Beginner',
    soak: 'Optional',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days.',
    stack: 'Yes',
    tip: 'Collards can tend to get crowded in the tray and hold moisture. Good airflow and humidity control will help prevent any mushy microgreens from forming in the middle of the tray.',
  },
  {
    name: 'Colorful Basil Microgreens Seed Mix',
    plant: 12,
    production: 142,
    flavor: 'Classic basil flavor with mild licorice undertones',
    difficulty: 'Intermediate',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '14 - 21+ Days, basil can be harvested very small for a extremely tender microgreen or allowed to grow large true leaves for a higher yeild',
    stack: 'No',
    tip: 'Avoid clumping of the seeds as they will stick together. Keep covered with a blackout dome until the sprouts are about 1/2" tall then place under light.',
  },
  {
    name: 'Colorful Mustard Microgreens Seed Mix',
    plant: 20,
    production: 224,
    flavor: 'From spicy to mild mustard flavors',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days',
    stack: 'Yes',
    tip: 'Allow the mustars to develop their first true leaves to achieve the best colors and leave shapes.',
  },
  {
    name: 'Colorful Radish Microgreens Mix',
    plant: 38,
    production: 400,
    flavor: 'Very recognizable radish flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days',
    stack: 'Yes',
    tip: 'Leave stacked until they lift the tray above, radish is so easy to grow, you almost cannot mess them up.',
  },
  {
    name: 'Corn - Yellow Popcorn (Organic) - Microgreens Seeds',
    plant: 280,
    production: 170,
    flavor: 'Very sweet, almost artifial sweetener flavored',
    difficulty: 'Advanced',
    soak: 'Optional',
    method: 'Soil',
    harvestTime: '10 - 14 Days',
    stack: 'Yes',
    tip: 'Cover the seeds with a thin layer of soil or coco coir. Grow corn shoots completely in the dark to achieve bright yellow shoots.',
  },
  {
    name: 'Cress - Curled - Microgreens Seeds',
    plant: 10,
    production: 170,
    flavor: 'Fresh peppery flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days, Cress germinates very quickly',
    stack: 'Optional',
    tip: 'Cress seeds are very sticky when wet, spread very evenly to avoid clumping. Misting daily can help to allow the microgreens to shed the seeds.',
  },
  {
    name: 'Cress - Upland - Microgreens Seeds',
    plant: 10,
    production: 170,
    flavor: 'Fresh peppery flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days, Cress germinates very quickly',
    stack: 'Optional',
    tip: 'Cress seeds are very sticky when wet, spread very evenly to avoid clumping. Misting daily can help to allow the microgreens to shed the seeds. Upland Cress grows much shorter than curled cress.',
  },
  {
    name: 'Crimson Clover - Organic - Microgreens Seeds',
    plant: 10,
    production: 142,
    flavor: 'Mild flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days.',
    stack: 'Yes',
    tip: 'Clover grows very short, place under a blackout dome to get them to stretch a little taller before introducing to light.',
  },
  {
    name: 'Dill - Bouquet - Microgreens Seeds',
    plant: 10,
    production: 114,
    flavor: 'Just like a dill pickle',
    difficulty: 'Intermediate',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '14 - 21 Days.',
    stack: 'Optional',
    tip: 'Once the seeds have germinated, place under a clear hunidity dome to help keep them moist and allow the seed hulls to fall off.',
  },
  {
    name: 'Endive - Broadleaf Batavian - Microgreens Seeds',
    plant: 10,
    production: 114,
    flavor: 'Slightly bitter',
    difficulty: 'Intermediate',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Optional',
    tip: 'Endive prefers cooler temperatures.',
  },
  {
    name: 'Endive - Green Curled Ruffec - Microgreens Seeds',
    plant: 10,
    production: 114,
    flavor: 'Slightly bitter',
    difficulty: 'Intermediate',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Optional',
    tip: 'Endive prefers cooler temperatures.',
  },
  {
    name: 'Fennel - Bronze - Microgreens Seeds',
    plant: 10,
    production: 114,
    flavor: 'Strong licorice flavor, great for desserts',
    difficulty: 'Intermediate',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '14 - 21 Days.',
    stack: 'Optional',
    tip: 'Once the seeds have germinated, place under a clear hunidity dome to help keep them moist and allow the seed hulls to fall off.',
  },
  {
    name: 'Fennel - Florence - Microgreens Seeds',
    plant: 10,
    production: 114,
    flavor: 'Strong licorice flavor, great for desserts',
    difficulty: 'Intermediate',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '14 - 21 Days.',
    stack: 'Optional',
    tip: 'Once the seeds have germinated, place under a clear hunidity dome to help keep them moist and allow the seed hulls to fall off.',
  },
  {
    name: 'Fenugreek (organic) - Microgreens Seeds',
    plant: 60,
    production: 300,
    flavor: 'Very bitter',
    difficulty: 'Intermediate',
    soak: 'Recommended',
    method: 'Soil',
    harvestTime: '7 - 10 Days.',
    stack: 'Yes',
    tip: 'Mist daily once germinated to help keep the seed hulls soft so they fall off.',
  },
  {
    name: 'Kale - Black Tuscan aka Lacinato or Dinosaur - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Mild kale flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Allow kale to grow its first true leaves to expose its beautiful shape.',
  },
  {
    name: 'Kale - Black Tuscan aka Lacinato or Dinosaur (Organic) - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Mild kale flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Allow kale to grow its first true leaves to expose its beautiful shape.',
  },
  {
    name: 'Kale - Dwarf Siberian - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Mild kale flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Allow kale to grow its first true leaves to expose its beautiful shape.',
  },
  {
    name: 'Kale - Red Russian - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Mild kale flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Allow kale to grow its first true leaves to expose its beautiful shape.',
  },
  {
    name: 'Kale - Vates Blue Scotch Curled - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Mild kale flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Allow kale to grow its first true leaves to expose its beautiful shape.',
  },
  {
    name: 'Kale - Vates Blue Scotch Curled - Microgreens Seeds (Organic)',
    plant: 20,
    production: 224,
    flavor: 'Mild kale flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Allow kale to grow its first true leaves to expose its beautiful shape.',
  },
  {
    name: 'Kale Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Mild kale flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Allow kale to grow its first true leaves to expose its beautiful shape.',
  },
  {
    name: 'Kohlrabi - Early White Vienna - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Similar pungent flavor of broccoli',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Harvest just before the first true leafs come out for a tender microgreen.',
  },
  {
    name: 'Kohlrabi - Purple Vienna - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Similar pungent flavor of broccoli',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Harvest just before the first true leafs come out for a tender microgreen.',
  },
  {
    name: 'Kohlrabi - Purple Vienna (Organic) - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Similar pungent flavor of broccoli',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Harvest just before the first true leafs come out for a tender microgreen.',
  },
  {
    name: 'Leek - Large American Flag - Microgreens Seeds',
    plant: 30,
    production: 85,
    flavor: 'Distinct onion flavor',
    difficulty: 'Intermediate',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Optional',
    tip: 'Once in troducing to light, cover with a clear humdity dome for a few days to keep the seedlings moist.',
  },
  {
    name: 'Lemon Balm - Microgreens Seeds',
    plant: 12,
    production: 142,
    flavor: 'Fresh lemon like flavor',
    difficulty: 'Advanced',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '21+ Days.',
    stack: 'Optional',
    tip: 'Use a clear humidity dome to keep the seeds moist for at least the first few day.',
  },
  {
    name: 'Lettuce - Crisphead Great Lakes 118 - Microgreens Seeds',
    plant: 15,
    production: 170,
    flavor: 'Mild lettuce flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Optional',
    tip: 'Allow to grow close to a baby green stage to increase yeild and remain tender.',
  },
  {
    name: 'Lettuce - Gourmet Mixture - Microgreens Seeds',
    plant: 15,
    production: 170,
    flavor: 'Mild lettuce flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Optional',
    tip: 'Allow to grow close to a baby green stage to increase yeild and remain tender.',
  },
  {
    name: 'Lettuce - Microgreens Seeds',
    plant: 15,
    production: 170,
    flavor: 'Mild lettuce flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Optional',
    tip: 'Allow to grow close to a baby green stage to increase yeild and remain tender.',
  },
  {
    name: 'Lettuce - Parris Island Cos - Microgreens Seeds',
    plant: 15,
    production: 170,
    flavor: 'Mild lettuce flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Optional',
    tip: 'Allow to grow close to a baby green stage to increase yeild and remain tender.',
  },
  {
    name: 'Lettuce - Waldmanns Green - Microgreens Seeds',
    plant: 15,
    production: 170,
    flavor: 'Mild lettuce flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Optional',
    tip: 'Allow to grow close to a baby green stage to increase yeild and remain tender.',
  },
  {
    name: 'Lettuce Crisphead - Hanson Improved - Microgreens Seeds',
    plant: 15,
    production: 170,
    flavor: 'Mild lettuce flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Optional',
    tip: 'Allow to grow close to a baby green stage to increase yeild and remain tender.',
  },
  {
    name: 'Lettuce Crisphead - Iceberg - Microgreens Seeds',
    plant: 15,
    production: 170,
    flavor: 'Mild lettuce flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Optional',
    tip: 'Allow to grow close to a baby green stage to increase yeild and remain tender.',
  },
  {
    name: 'Lettuce Gourmet Mix (organic) - Microgreens Seeds',
    plant: 15,
    production: 170,
    flavor: 'Mild lettuce flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Optional',
    tip: 'Allow to grow close to a baby green stage to increase yeild and remain tender.',
  },
  {
    name: 'Lettuce Leaf - Grand Rapids - Microgreens Seeds',
    plant: 15,
    production: 170,
    flavor: 'Mild lettuce flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Optional',
    tip: 'Allow to grow close to a baby green stage to increase yeild and remain tender.',
  },
  {
    name: 'Lettuce Leaf - Lollo Rosso (Organic) - Microgreens Seeds',
    plant: 15,
    production: 170,
    flavor: 'Mild lettuce flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Optional',
    tip: 'Allow to grow close to a baby green stage to increase yeild and remain tender.',
  },
  {
    name: 'Lettuce Leaf - Red Sails - Microgreens Seeds',
    plant: 15,
    production: 170,
    flavor: 'Mild lettuce flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Optional',
    tip: 'Allow to grow close to a baby green stage to increase yeild and remain tender.',
  },
  {
    name: 'Lettuce Leaf - Ruby Red - Microgreens Seeds',
    plant: 15,
    production: 170,
    flavor: 'Mild lettuce flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Optional',
    tip: 'Allow to grow close to a baby green stage to increase yeild and remain tender.',
  },
  {
    name: 'Lettuce Leaf - Salad Bowl Green - Microgreens Seeds',
    plant: 15,
    production: 170,
    flavor: 'Mild lettuce flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Optional',
    tip: 'Allow to grow close to a baby green stage to increase yeild and remain tender.',
  },
  {
    name: 'Lettuce Leaf - Salad Bowl Red - Microgreens Seeds',
    plant: 15,
    production: 170,
    flavor: 'Mild lettuce flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Optional',
    tip: 'Allow to grow close to a baby green stage to increase yeild and remain tender.',
  },
  {
    name: 'Lettuce Mixed Greens - Mesclun Mix - Microgreens Seeds',
    plant: 15,
    production: 170,
    flavor: 'Mild lettuce flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Optional',
    tip: 'Allow to grow close to a baby green stage to increase yeild and remain tender.',
  },
  {
    name: 'Lettuce Romaine - Cimmaron - Microgreens Seeds',
    plant: 15,
    production: 170,
    flavor: 'Mild lettuce flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Optional',
    tip: 'Allow to grow close to a baby green stage to increase yeild and remain tender.',
  },
  {
    name: 'Lettuce, Romaine - Freckles - Microgreens Seeds',
    plant: 15,
    production: 170,
    flavor: 'Mild lettuce flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Optional',
    tip: 'Allow to grow close to a baby green stage to increase yeild and remain tender.',
  },
  {
    name: 'Lettuce, Romaine - Little Gem - Microgreens Seeds',
    plant: 15,
    production: 170,
    flavor: 'Mild lettuce flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Optional',
    tip: 'Allow to grow close to a baby green stage to increase yeild and remain tender.',
  },
  {
    name: 'Marigold Microgreens Seeds - Minuta, Edible',
    plant: 10,
    production: 114,
    flavor: 'A minty flavored microgreen',
    difficulty: 'Intermediate',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Optional',
    tip: 'Harvest before they get too big for the best flavor and tenderness.',
  },
  {
    name: 'Mustard - Mizuna - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Mild mustard flavor that is not too spicy',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Grow until first true leaves show to get the best colors and leaf shapes.',
  },
  {
    name: 'Mustard - Mizuna Red Leaf - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Mild mustard flavor that is not too spicy',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Grow until first true leaves show to get the best colors and leaf shapes.',
  },
  {
    name: 'Mustard - Mizuna Red Streaks - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Mild mustard flavor that is not too spicy',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Grow until first true leaves show to get the best colors and leaf shapes.',
  },
  {
    name: 'Mustard - Mizuna Red Streaks (Organic) - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Mild mustard flavor that is not too spicy',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Grow until first true leaves show to get the best colors and leaf shapes.',
  },
  {
    name: 'Mustard - Osaka Purple - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Very strong mustard flavor with a spicy kick',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Grow until first true leaves show to get the best colors and leaf shapes.',
  },
  {
    name: 'Mustard - Red Garnet - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Mild mustard flavor more spicy than mizuna but not overpowering',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Grow until first true leaves show to get the best colors and leaf shapes.',
  },
  {
    name: 'Mustard - Red Giant - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Mild mustard flavor that is not too spicy',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Grow until first true leaves show to get the best colors and leaf shapes.',
  },
  {
    name: 'Mustard - Southern Giant Curled - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Mild mustard flavor that is fairly spicy',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Grow until first true leaves show to get the best colors and leaf shapes.',
  },
  {
    name: 'Mustard - Spicy Oriental - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'A very spicy wasabi like flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Grow until first true leaves show to get the best colors and leaf shapes.',
  },
  {
    name: 'Mustard - Spicy Oriental (organic) - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'A very spicy wasabi like flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Grow until first true leaves show to get the best colors and leaf shapes.',
  },
  {
    name: 'Mustard - Spinach (Komatsuna) - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Mild mustard flavor with very little spiciness',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Grow until first true leaves show to get the best colors and leaf shapes.',
  },
  {
    name: 'Mustard - Tatsoi - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Mild mustard flavor that is not too spicy',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Grow until first true leaves show to get the best colors and leaf shapes.',
  },
  {
    name: 'Mustard - Tokyo Bekana - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Strong mustard flavor that is fairly spicy',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Grow until first true leaves show to get the best colors and leaf shapes.',
  },
  {
    name: 'Mustard - Wasabi - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Like the name, spicy wasabi',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Grow until first true leaves show to get the best colors and leaf shapes.',
  },
  {
    name: 'Mustard - Wasabi (Organic) - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Like the name, spicy wasabi',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Grow until first true leaves show to get the best colors and leaf shapes.',
  },
  {
    name: 'Mustard Seeds - Mizuna - Lime Streaks - Microgreens',
    plant: 20,
    production: 224,
    flavor: 'A slightly tangy mustard flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Grow until first true leaves show to get the best colors and leaf shapes.',
  },
  {
    name: 'Mustard Seeds - Red Tatsoi - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Mild mustard flavor that is not too spicy',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Grow until first true leaves show to get the best colors and leaf shapes.',
  },
  {
    name: 'Mustard, White Ice - Organic - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Mild mustard flavor that is not too spicy',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Grow until first true leaves show to get the best colors and leaf shapes.',
  },
  {
    name: 'Nasturtium - Empress of India - Microgreens Seeds',
    plant: 60,
    production: 142,
    flavor: 'Sweet, peppery and very spicy',
    difficulty: 'Intermediate',
    soak: 'Yes',
    method: 'Soil',
    harvestTime: '10 - 21 Days.',
    stack: 'Yes',
    tip: 'Cover ther seeds with a layer of soil or coco coir. Harvest and enjoy just the leaves or the entire shoot.',
  },
  {
    name: 'Nasturtium - Jewel Mix - Microgreens Seeds',
    plant: 60,
    production: 142,
    flavor: 'A peppery spicy shoot',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Cover ther seeds with a layer of soil or coco coir. Harvest and enjoy just the leaves or the entire shoot.',
  },
  {
    name: 'Nasturtium - Whirlybird Mix - Microgreens Seeds',
    plant: 60,
    production: 142,
    flavor: 'A complex blend of sweetness and peppery spice',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Cover ther seeds with a layer of soil or coco coir. Harvest and enjoy just the leaves or the entire shoot.',
  },
  {
    name: 'Nasturtium Seeds - Alaska - Microgreens Seeds',
    plant: 60,
    production: 142,
    flavor: 'A peppery spicy shoot',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Cover ther seeds with a layer of soil or coco coir. Harvest and enjoy just the leaves or the entire shoot.',
  },
  {
    name: 'Onion - Bunching - Evergreen (organic) - Microgreens Seeds',
    plant: 10,
    production: 85,
    flavor: 'Green onion flavor with notes of garlic',
    difficulty: 'Advanced',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '14 - 21 Days.',
    stack: 'Optional',
    tip: 'Use a clear humidity dome to keep the seedlings moist. The seeds have a pleasent nuttiness with an easy to consume crunch',
  },
  {
    name: 'Onion - Bunching - Tokyo Long White - Microgreens Seeds',
    plant: 10,
    production: 85,
    flavor: 'Green onion flavor with notes of garlic',
    difficulty: 'Advanced',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '14 - 21 Days.',
    stack: 'Optional',
    tip: 'Use a clear humidity dome to keep the seedlings moist. The seeds have a pleasent nuttiness with an easy to consume crunch',
  },
  {
    name: 'Onion - Red Burgundy - Microgreens Seeds',
    plant: 10,
    production: 85,
    flavor: 'Green onion flavor with notes of garlic',
    difficulty: 'Advanced',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '14 - 21 Days.',
    stack: 'Optional',
    tip: 'Use a clear humidity dome to keep the seedlings moist. The seeds have a pleasent nuttiness with an easy to consume crunch',
  },
  {
    name: 'Onion - Utah Yellow Sweet Spanish - Microgreens Seeds',
    plant: 10,
    production: 85,
    flavor: 'Green onion flavor with notes of garlic',
    difficulty: 'Advanced',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '14 - 21 Days.',
    stack: 'Optional',
    tip: 'Use a clear humidity dome to keep the seedlings moist. The seeds have a pleasent nuttiness with an easy to consume crunch',
  },
  {
    name: 'Onion - White Sweet Spanish - Microgreens Seeds',
    plant: 10,
    production: 85,
    flavor: 'Green onion flavor with notes of garlic',
    difficulty: 'Advanced',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '14 - 21 Days.',
    stack: 'Optional',
    tip: 'Use a clear humidity dome to keep the seedlings moist. The seeds have a pleasent nuttiness with an easy to consume crunch',
  },
  {
    name: 'Onion (organic) - Microgreens Seeds',
    plant: 10,
    production: 85,
    flavor: 'Green onion flavor with notes of garlic',
    difficulty: 'Advanced',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '14 - 21 Days.',
    stack: 'Optional',
    tip: 'Use a clear humidity dome to keep the seedlings moist. The seeds have a pleasent nuttiness with an easy to consume crunch',
  },
  {
    name: 'Orach - Purple - Microgreens Seeds',
    plant: 10,
    production: 114,
    flavor: 'A bitter spinach flavor',
    difficulty: 'Advanced',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '14 - 21 Days.',
    stack: 'Optional',
    tip: 'These seeds are very small, be careful to spread evenly. Use a clear humidity dome to keep the seedlings moist.',
  },
  {
    name: 'Oregano - Common Italian - Microgreens Seeds',
    plant: 10,
    production: 85,
    flavor: 'Orengano',
    difficulty: 'Advanced',
    soak: 'No',
    method: 'Soil',
    harvestTime: '21+ Days.',
    stack: 'Optional',
    tip: 'Use a clear humidity dome to keep the seedlings moist.',
  },
  {
    name: 'Oregano - Common Italian (organic) - Microgreens Seeds',
    plant: 10,
    production: 85,
    flavor: 'Oregano',
    difficulty: 'Advanced',
    soak: 'No',
    method: 'Soil',
    harvestTime: '21+ Days.',
    stack: 'Optional',
    tip: 'Use a clear humidity dome to keep the seedlings moist.',
  },
  {
    name: 'Parsley - Dark Green Italian Flat-leaf - Microgreens Seeds',
    plant: 15,
    production: 114,
    flavor: 'Parsley flavor',
    difficulty: 'Intermediate',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '21+ Days.',
    stack: 'Yes',
    tip: 'Use a clear humidity dome to keep the seedlings moist.',
  },
  {
    name: 'Parsley - Triple Moss Curled - Microgreens Seeds',
    plant: 15,
    production: 114,
    flavor: 'Parsley flavor',
    difficulty: 'Intermediate',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '21+ Days.',
    stack: 'Yes',
    tip: 'Use a clear humidity dome to keep the seedlings moist.',
  },
  {
    name: 'Parsley Microgreens Seeds',
    plant: 15,
    production: 114,
    flavor: 'Parsley flavor',
    difficulty: 'Intermediate',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '21+ Days.',
    stack: 'Yes',
    tip: 'Use a clear humidity dome to keep the seedlings moist.',
  },
  {
    name: 'Pea - Dun - Microgreens Seeds',
    plant: 400,
    production: 455,
    flavor: 'Sweet pea flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '10 Days.',
    stack: 'Yes',
    tip: 'Soak seeds for 3 hours. Spread on damp soil and cover with a thin layer of soil. Stack trays for 3 days or until shoots are lifting the tray above. Place under lights and harvest once the leaves have opened up. The sooner you harvest the more tender the shoots will be.',
  },
  {
    name: 'Pea - Dundale, Dun Pea (Organic) - Microgreens',
    plant: 400,
    production: 455,
    flavor: 'Sweet pea flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '10 Days.',
    stack: 'Yes',
    tip: 'Soak seeds for 3 hours. Spread on damp soil and cover with a thin layer of soil. Stack trays for 3 days or until shoots are lifting the tray above. Place under lights and harvest once the leaves have opened up. The sooner you harvest the more tender the shoots will be.',
  },
  {
    name: 'Pea - Early Frosty - Microgreens Seeds',
    plant: 400,
    production: 455,
    flavor: 'Sweet pea flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '10 Days.',
    stack: 'Yes',
    tip: 'Soak seeds for 3 hours. Spread on damp soil and cover with a thin layer of soil. Stack trays for 3 days or until shoots are lifting the tray above. Place under lights and harvest once the leaves have opened up. The sooner you harvest the more tender the shoots will be.',
  },
  {
    name: 'Pea - Green (Organic) - Microgreens Seeds',
    plant: 400,
    production: 455,
    flavor: 'Sweet pea flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '10 Days.',
    stack: 'Yes',
    tip: 'Soak seeds for 3 hours. Spread on damp soil and cover with a thin layer of soil. Stack trays for 3 days or until shoots are lifting the tray above. Place under lights and harvest once the leaves have opened up. The sooner you harvest the more tender the shoots will be.',
  },
  {
    name: 'Pea - Green Arrow - Microgreens Seeds',
    plant: 400,
    production: 455,
    flavor: 'Sweet pea flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '10 Days.',
    stack: 'Yes',
    tip: 'Soak seeds for 3 hours. Spread on damp soil and cover with a thin layer of soil. Stack trays for 3 days or until shoots are lifting the tray above. Place under lights and harvest once the leaves have opened up. The sooner you harvest the more tender the shoots will be.',
  },
  {
    name: 'Pea - Lincoln - Microgreens Seeds',
    plant: 400,
    production: 455,
    flavor: 'Sweet pea flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '10 Days.',
    stack: 'Yes',
    tip: 'Soak seeds for 3 hours. Spread on damp soil and cover with a thin layer of soil. Stack trays for 3 days or until shoots are lifting the tray above. Place under lights and harvest once the leaves have opened up. The sooner you harvest the more tender the shoots will be.',
  },
  {
    name: 'Pea - Little Marvel - Microgreens Seeds',
    plant: 400,
    production: 455,
    flavor: 'Sweet pea flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '10 Days.',
    stack: 'Yes',
    tip: 'Soak seeds for 3 hours. Spread on damp soil and cover with a thin layer of soil. Stack trays for 3 days or until shoots are lifting the tray above. Place under lights and harvest once the leaves have opened up. The sooner you harvest the more tender the shoots will be.',
  },
  {
    name: 'Pea - Snow - Dwarf Sugar Grey - Microgreens Seeds',
    plant: 400,
    production: 455,
    flavor: 'Sweet pea flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '10 Days.',
    stack: 'Yes',
    tip: 'Soak seeds for 3 hours. Spread on damp soil and cover with a thin layer of soil. Stack trays for 3 days or until shoots are lifting the tray above. Place under lights and harvest once the leaves have opened up. The sooner you harvest the more tender the shoots will be.',
  },
  {
    name: 'Pea - Snow - Mammoth Melting Sugar Pod - Microgreens Seeds',
    plant: 400,
    production: 455,
    flavor: 'Sweet pea flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '10 Days.',
    stack: 'Yes',
    tip: 'Soak seeds for 3 hours. Spread on damp soil and cover with a thin layer of soil. Stack trays for 3 days or until shoots are lifting the tray above. Place under lights and harvest once the leaves have opened up. The sooner you harvest the more tender the shoots will be.',
  },
  {
    name: 'Pea - Speckled - Microgreens Seeds',
    plant: 400,
    production: 455,
    flavor: 'Sweet pea flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '10 Days.',
    stack: 'Yes',
    tip: 'Soak seeds for 3 hours. Spread on damp soil and cover with a thin layer of soil. Stack trays for 3 days or until shoots are lifting the tray above. Place under lights and harvest once the leaves have opened up. The sooner you harvest the more tender the shoots will be.',
  },
  {
    name: 'Pea - Speckled (Organic) - Microgreens Seeds',
    plant: 400,
    production: 455,
    flavor: 'Sweet pea flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '10 Days.',
    stack: 'Yes',
    tip: 'Soak seeds for 3 hours. Spread on damp soil and cover with a thin layer of soil. Stack trays for 3 days or until shoots are lifting the tray above. Place under lights and harvest once the leaves have opened up. The sooner you harvest the more tender the shoots will be.',
  },
  {
    name: 'Pea - Speckled (Arivka) (Organic) - Microgreens Seeds',
    plant: 400,
    production: 455,
    flavor: 'Sweet pea flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '10 Days.',
    stack: 'Yes',
    tip: 'Soak seeds for 3 hours. Spread on damp soil and cover with a thin layer of soil. Stack trays for 3 days or until shoots are lifting the tray above. Place under lights and harvest once the leaves have opened up. The sooner you harvest the more tender the shoots will be.',
  },
  {
    name: 'Pea - Tendril - Microgreens Seeds',
    plant: 400,
    production: 455,
    flavor: 'Sweet pea flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '10 Days.',
    stack: 'Yes',
    tip: 'Soak seeds for 3 hours. Spread on damp soil and cover with a thin layer of soil. Stack trays for 3 days or until shoots are lifting the tray above. Place under lights and harvest once the leaves have opened up. The sooner you harvest the more tender the shoots will be.',
  },
  {
    name: 'Pea - Thomas Laxton - Microgreens Seeds',
    plant: 400,
    production: 455,
    flavor: 'Sweet pea flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '10 Days.',
    stack: 'Yes',
    tip: 'Soak seeds for 3 hours. Spread on damp soil and cover with a thin layer of soil. Stack trays for 3 days or until shoots are lifting the tray above. Place under lights and harvest once the leaves have opened up. The sooner you harvest the more tender the shoots will be.',
  },
  {
    name: 'Pea - Yellow (Organic) - Microgreens Seeds',
    plant: 400,
    production: 455,
    flavor: 'Sweet pea flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '10 Days.',
    stack: 'Yes',
    tip: 'Soak seeds for 3 hours. Spread on damp soil and cover with a thin layer of soil. Stack trays for 3 days or until shoots are lifting the tray above. Place under lights and harvest once the leaves have opened up. The sooner you harvest the more tender the shoots will be.',
  },
  {
    name: 'Radish - Champion - Microgreens Seeds',
    plant: 38,
    production: 400,
    flavor: 'Spicy radish with a bit of sweetness',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days.',
    stack: 'Yes',
    tip: 'A super easy to grow microgreen. Spread seeds evenly over damp soil and mist seeds. Stack trays for 3 days then place dirrectly under lights. Harvest when the cotyledon leaves are full.',
  },
  {
    name: 'Radish - China Rose (Conventional) - Microgreens Seeds',
    plant: 38,
    production: 400,
    flavor: 'Spicy radish with a bit of sweetness',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days.',
    stack: 'Yes',
    tip: 'A super easy to grow microgreen. Spread seeds evenly over damp soil and mist seeds. Stack trays for 3 days then place dirrectly under lights. Harvest when the cotyledon leaves are full.',
  },
  {
    name: 'Radish - China Rose (Organic) - Microgreens Seeds',
    plant: 38,
    production: 400,
    flavor: 'Spicy radish with a bit of sweetness',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days.',
    stack: 'Yes',
    tip: 'A super easy to grow microgreen. Spread seeds evenly over damp soil and mist seeds. Stack trays for 3 days then place dirrectly under lights. Harvest when the cotyledon leaves are full.',
  },
  {
    name: 'Radish - Daikon (Organic) - Microgreens Seeds',
    plant: 38,
    production: 400,
    flavor: 'Spicy radish with a bit of sweetness',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days.',
    stack: 'Yes',
    tip: 'A super easy to grow microgreen. Spread seeds evenly over damp soil and mist seeds. Stack trays for 3 days then place dirrectly under lights. Harvest when the cotyledon leaves are full.',
  },
  {
    name: 'Radish - Hong Vit (organic) - Microgreens Seeds',
    plant: 38,
    production: 400,
    flavor: 'Spicy radish with a bit of sweetness',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days.',
    stack: 'Yes',
    tip: 'A super easy to grow microgreen. Spread seeds evenly over damp soil and mist seeds. Stack trays for 3 days then place dirrectly under lights. Harvest when the cotyledon leaves are full.',
  },
  {
    name: 'Radish - Minowase - Microgreens Seeds',
    plant: 38,
    production: 400,
    flavor: 'Spicy radish with a bit of sweetness',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days.',
    stack: 'Yes',
    tip: 'A super easy to grow microgreen. Spread seeds evenly over damp soil and mist seeds. Stack trays for 3 days then place dirrectly under lights. Harvest when the cotyledon leaves are full.',
  },
  {
    name: 'Radish - Purple - Microgreens Seeds',
    plant: 38,
    production: 400,
    flavor: 'Spicy radish with a bit of sweetness',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days.',
    stack: 'Yes',
    tip: 'A super easy to grow microgreen. Spread seeds evenly over damp soil and mist seeds. Stack trays for 3 days then place dirrectly under lights. Harvest when the cotyledon leaves are full.',
  },
  {
    name: 'Radish - Rambo (Conventional) - Microgreens Seeds',
    plant: 38,
    production: 400,
    flavor: 'Spicy radish with a bit of sweetness',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days.',
    stack: 'Yes',
    tip: 'A super easy to grow microgreen. Spread seeds evenly over damp soil and mist seeds. Stack trays for 3 days then place dirrectly under lights. Harvest when the cotyledon leaves are full.',
  },
  {
    name: 'Radish - Rambo (Organic) - Microgreens Seeds',
    plant: 38,
    production: 400,
    flavor: 'Spicy radish with a bit of sweetness',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days.',
    stack: 'Yes',
    tip: 'A super easy to grow microgreen. Spread seeds evenly over damp soil and mist seeds. Stack trays for 3 days then place dirrectly under lights. Harvest when the cotyledon leaves are full.',
  },
  {
    name: 'Radish - Red Arrow - Microgreens Seeds',
    plant: 38,
    production: 400,
    flavor: 'Spicy radish with a bit of sweetness',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days.',
    stack: 'Yes',
    tip: 'A super easy to grow microgreen. Spread seeds evenly over damp soil and mist seeds. Stack trays for 3 days then place dirrectly under lights. Harvest when the cotyledon leaves are full.',
  },
  {
    name: 'Radish - Red Daikon (Ostergruss Rosa) - Microgreens Seeds',
    plant: 38,
    production: 400,
    flavor: 'Spicy radish with a bit of sweetness',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days.',
    stack: 'Yes',
    tip: 'A super easy to grow microgreen. Spread seeds evenly over damp soil and mist seeds. Stack trays for 3 days then place dirrectly under lights. Harvest when the cotyledon leaves are full.',
  },
  {
    name: 'Radish - Sango Purple - Microgreens Seeds',
    plant: 38,
    production: 400,
    flavor: 'Spicy radish with a bit of sweetness',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days.',
    stack: 'Yes',
    tip: 'A super easy to grow microgreen. Spread seeds evenly over damp soil and mist seeds. Stack trays for 3 days then place dirrectly under lights. Harvest when the cotyledon leaves are full.',
  },
  {
    name: 'Radish - Sango Purple (Organic) - Microgreens Seeds',
    plant: 38,
    production: 400,
    flavor: 'Spicy radish with a bit of sweetness',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days.',
    stack: 'Yes',
    tip: 'A super easy to grow microgreen. Spread seeds evenly over damp soil and mist seeds. Stack trays for 3 days then place dirrectly under lights. Harvest when the cotyledon leaves are full.',
  },
  {
    name: 'Radish - Sparkler - Microgreens Seeds',
    plant: 38,
    production: 400,
    flavor: 'Spicy radish with a bit of sweetness',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days.',
    stack: 'Yes',
    tip: 'A super easy to grow microgreen. Spread seeds evenly over damp soil and mist seeds. Stack trays for 3 days then place dirrectly under lights. Harvest when the cotyledon leaves are full.',
  },
  {
    name: 'Radish - Triton Purple - Microgreens Seeds',
    plant: 38,
    production: 400,
    flavor: 'Spicy radish with a bit of sweetness',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days.',
    stack: 'Yes',
    tip: 'A super easy to grow microgreen. Spread seeds evenly over damp soil and mist seeds. Stack trays for 3 days then place dirrectly under lights. Harvest when the cotyledon leaves are full.',
  },
  {
    name: 'Radish Seeds - Crayon Colors Mix - Microgreens',
    plant: 38,
    production: 400,
    flavor: 'Spicy radish with a bit of sweetness',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days.',
    stack: 'Yes',
    tip: 'A super easy to grow microgreen. Spread seeds evenly over damp soil and mist seeds. Stack trays for 3 days then place dirrectly under lights. Harvest when the cotyledon leaves are full.',
  },
  {
    name: 'Radish Seeds - Red Vulcano (Organic) - Microgreens Seeds',
    plant: 38,
    production: 400,
    flavor: 'Spicy radish with a bit of sweetness',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 10 Days.',
    stack: 'Yes',
    tip: 'A super easy to grow microgreen. Spread seeds evenly over damp soil and mist seeds. Stack trays for 3 days then place dirrectly under lights. Harvest when the cotyledon leaves are full.',
  },
  {
    name: 'Red Cabbage Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Mild cabbage flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Cabbage can tend to get crowded in the tray and hold moisture. Good airflow and humidity control will help prevent any mushy microgreens from forming in the middle of the tray.',
  },
  {
    name: 'Rutabaga - American Purple Top - Microgreens Seeds',
    plant: 20,
    production: 224,
    flavor: 'Mild broccoli flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 Days.',
    stack: 'Yes',
    tip: 'Rutabaga grows best in lower light conditions',
  },
  {
    name: 'Sage Herb Seeds - Organic Microgreens Seeds',
    plant: 5,
    production: 85,
    flavor: 'Strong flavor of its adult counterpart',
    difficulty: 'Intermediate',
    soak: 'No',
    method: 'Soil',
    harvestTime: '14 - 21+ Days.',
    stack: 'Optional',
    tip: 'Sage seeds are very small, take the time to spread as evenly as you can.',
  },
  {
    name: 'Seed Assortment: Basic Dozen Microgreens',
    plant: 0,
    production: 0,
    flavor: 'Assorted',
    difficulty: 'Assorted',
    soak: 'Assorted',
    method: 'Assorted',
    harvestTime: 'Assorted.',
    stack: 'Assorted',
    tip: 'Enjoy the assortment',
  },
  {
    name: 'Seed Assortment: Colorful Microgreens',
    plant: 0,
    production: 0,
    flavor: 'Assorted',
    difficulty: 'Assorted',
    soak: 'Assorted',
    method: 'Assorted',
    harvestTime: 'Assorted.',
    stack: 'Assorted',
    tip: 'Enjoy the assortment',
  },
  {
    name: 'Seed Assortment: Exotic Microgreens',
    plant: 0,
    production: 0,
    flavor: 'Assorted',
    difficulty: 'Assorted',
    soak: 'Assorted',
    method: 'Assorted',
    harvestTime: 'Assorted.',
    stack: 'Assorted',
    tip: 'Enjoy the assortment',
  },
  {
    name: 'Seed Assortment: Microgreen Seeds - Soil Selection',
    plant: 0,
    production: 0,
    flavor: 'Assorted',
    difficulty: 'Assorted',
    soak: 'Assorted',
    method: 'Assorted',
    harvestTime: 'Assorted.',
    stack: 'Assorted',
    tip: 'Enjoy the assortment',
  },
  {
    name: 'Shiso (Perilla) - Green - Microgreens Seeds',
    plant: 15,
    production: 170,
    flavor: 'Complex minty basil',
    difficulty: 'Intermediate',
    soak: 'Yes',
    method: 'Soil or Hydroponic',
    harvestTime: '10 - 14 days.',
    stack: 'Yes',
    tip: 'Shiso seeds have a very hard shell that can be softened with an overnight soak in cold water.',
  },
  {
    name: 'Shiso (Perilla) - Purple - Microgreens Seeds',
    plant: 15,
    production: 170,
    flavor: 'Complex minty basil',
    difficulty: 'Intermediate',
    soak: 'Yes',
    method: 'Soil or Hydroponic',
    harvestTime: '10 - 14 days.',
    stack: 'Yes',
    tip: 'Shiso seeds have a very hard shell that can be softened with an overnight soak in cold water.',
  },
  {
    name: 'Shungiku (3 Color Daisy) Seeds- Microgreens',
    plant: 10,
    production: 85,
    flavor: 'Slightly bitter carrot flavor',
    difficulty: 'Intermediate',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '14 - 21 days.',
    stack: 'Yes',
    tip: 'Use a clear humidity dome to keep seedlings moist during the first few days of introducing to light.',
  },
  {
    name: 'Sorrel - Large Leaf - Microgreens Seeds',
    plant: 3,
    production: 28,
    flavor: 'Citrus lemon like flavor',
    difficulty: 'Advanced',
    soak: 'No',
    method: 'Soil',
    harvestTime: '21+ days.',
    stack: 'Optional',
    tip: 'Freezing sorrel seeds helps with germination. Cut and use just the leaves for a continuous growing microgreen',
  },
  {
    name: 'Sorrel - Red Veined - Microgreens Seeds',
    plant: 3,
    production: 28,
    flavor: 'Citrus lemon like flavor',
    difficulty: 'Advanced',
    soak: 'No',
    method: 'Soil',
    harvestTime: '21+ days.',
    stack: 'Optional',
    tip: 'Freezing sorrel seeds helps with germination. Cut and use just the leaves for a continuous growing microgreen',
  },
  {
    name: 'Spicy Salad Mix - Microgreens Seeds',
    plant: 15,
    production: 200,
    flavor: 'A nice blend of cabbage and arugula with a spicy mustard',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 days.',
    stack: 'Optional',
    tip: 'The arugula germinates quicker than the cabbage, allow all varieties to germinate and root before introducing to light.',
  },
  {
    name: 'Spicy Salad Mix (Organic) - Microgreens Seeds',
    plant: 15,
    production: 200,
    flavor: 'A nice blend of cabbage and arugula with a spicy mustard',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 days.',
    stack: 'Optional',
    tip: 'The arugula germinates quicker than the cabbage, allow all varieties to germinate and root before introducing to light.',
  },
  {
    name: 'Sunflower - Black Oil (Conventional) - Microgreens Seeds',
    plant: 200,
    production: 460,
    flavor: 'A crisp nutty snack',
    difficulty: 'Intermediate',
    soak: 'Yes',
    method: 'Soil',
    harvestTime: '7 - 14 days.',
    stack: 'Yes',
    tip: 'Leave trays stacked until they begin to tip over. Top water daily and rub the tops to help dislodge seed hulls.',
  },
  {
    name: 'Sunflower - Black Oil (organic) - Microgreens Seeds',
    plant: 200,
    production: 460,
    flavor: 'A crisp nutty snack',
    difficulty: 'Intermediate',
    soak: 'Yes',
    method: 'Soil',
    harvestTime: '7 - 14 days.',
    stack: 'Yes',
    tip: 'Leave trays stacked until they begin to tip over. Top water daily and rub the tops to help dislodge seed hulls.',
  },
  {
    name: 'Sunflower Microgreens Seeds - Organic',
    plant: 200,
    production: 460,
    flavor: 'A crisp nutty snack',
    difficulty: 'Intermediate',
    soak: 'Yes',
    method: 'Soil',
    harvestTime: '7 - 14 days.',
    stack: 'Yes',
    tip: 'Leave trays stacked until they begin to tip over. Top water daily and rub the tops to help dislodge seed hulls.',
  },
  {
    name: 'Swiss Chard - Pink Lipstick - Microgreens Seeds',
    plant: 60,
    production: 142,
  },
  {
    name: 'Swiss Chard - Rainbow Mixture - Microgreens Seeds',
    plant: 60,
    production: 142,
    flavor: 'Mild earthy chard flavor',
    difficulty: 'Advanced',
    soak: 'Optional',
    method: 'Soil',
    harvestTime: '10 - 14 days.',
    stack: 'Yes',
    tip: 'Cover the seeds with a thin layer of very fine soil or coco coir. A clear hudidity dome can help dislodge seed hulls by keeping the seeds damp for the first couple days under lights.',
  },
  {
    name: 'Swiss Chard - Ruby Red - Microgreens Seeds',
    plant: 60,
    production: 142,
    flavor: 'Mild earthy chard flavor',
    difficulty: 'Advanced',
    soak: 'Optional',
    method: 'Soil',
    harvestTime: '10 - 14 days.',
    stack: 'Yes',
    tip: 'Cover the seeds with a thin layer of very fine soil or coco coir. A clear hudidity dome can help dislodge seed hulls by keeping the seeds damp for the first couple days under lights.',
  },
  {
    name: 'Swiss Chard Seeds - Ruby Red - Organic - Microgreens Seeds',
    plant: 60,
    production: 142,
    flavor: 'Mild earthy chard flavor',
    difficulty: 'Advanced',
    soak: 'Optional',
    method: 'Soil',
    harvestTime: '10 - 14 days.',
    stack: 'Yes',
    tip: 'Cover the seeds with a thin layer of very fine soil or coco coir. A clear hudidity dome can help dislodge seed hulls by keeping the seeds damp for the first couple days under lights.',
  },
  {
    name: 'Swiss Chard Seeds - Yellow - Microgreens',
    plant: 60,
    production: 142,
    flavor: 'Mild earthy chard flavor',
    difficulty: 'Advanced',
    soak: 'Optional',
    method: 'Soil',
    harvestTime: '10 - 14 days.',
    stack: 'Yes',
    tip: 'Cover the seeds with a thin layer of very fine soil or coco coir. A clear hudidity dome can help dislodge seed hulls by keeping the seeds damp for the first couple days under lights.',
  },
  {
    name: 'Swiss Chard Seeds - Yellow - Organic - Microgreens',
    plant: 60,
    production: 142,
    flavor: 'Mild earthy chard flavor',
    difficulty: 'Advanced',
    soak: 'Optional',
    method: 'Soil',
    harvestTime: '10 - 14 days.',
    stack: 'Yes',
    tip: 'Cover the seeds with a thin layer of very fine soil or coco coir. A clear hudidity dome can help dislodge seed hulls by keeping the seeds damp for the first couple days under lights.',
  },
  {
    name: 'Turnip - Purple Top White Globe - Microgreens Seeds',
    plant: 20,
    production: 200,
    flavor: 'Mild turnip flavor',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Soil or Hydroponic',
    harvestTime: '7 - 14 days.',
    stack: 'Yes',
    tip: 'Turnip microgreens prefer low light levels.',
  },
  {
    name: 'Ultimate Hydroponic Microgreens Seed Mix',
    plant: 15,
    production: 142,
    flavor: 'A nice mix with a nutty arugula kick',
    difficulty: 'Beginner',
    soak: 'No',
    method: 'Hydroponic, also grows well on soil',
    harvestTime: '10 - 14 days.',
    stack: 'No',
    tip: 'Spread the seeds as evenly as possible.',
  },
];

export default PlantingList;
