import React from 'react';
// import { Auth } from 'aws-amplify';
import { Button, Nav, Navbar } from 'react-bootstrap';
import {
  FcCalculator, FcHome, FcKindle, FcReadingEbook,
} from 'react-icons/fc';
// import { GiFarmer } from 'react-icons/gi';
// let currentUser;
// async function getUser() {
//   try {
//     currentUser = await Auth.currentAuthenticatedUser();
//   } catch (e) {
//     console.log(e);
//   }
//   return (currentUser);
// }

export default function NavBar() {
  return (
    <Navbar collapseOnSelect expand="lg" style={{ background: '#404040' }} variant="dark">
      <Navbar.Brand href="/" className="logo" style={{ marginLeft: 10 }}>Microgreens Master</Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/">
            <Button variant="secondary">
              <FcHome size={35} />
              Dashboard
            </Button>
          </Nav.Link>
          <Nav.Link href="calculator">
            <Button variant="secondary">
              <FcCalculator size={35} />
              Microgreen
              Calculator
            </Button>
          </Nav.Link>
          <Nav.Link href="course">
            <Button variant="secondary">
              <FcReadingEbook size={35} />
              Course
            </Button>
          </Nav.Link>
          <Nav.Link href="blog">
            <Button variant="secondary">
              <FcKindle size={35} />
              Blog
            </Button>
          </Nav.Link>
          {/* <Nav.Link href="seeds">
            <GiFarmer size={35} />
            Seeds
          </Nav.Link> */}
        </Nav>
        {/* <Nav>
          {getUser()
            ? (
              <Nav.Link href="signin">
                <Button variant="secondary">
                  Sign In
                </Button>
              </Nav.Link>
            )
            : (
              <Nav.Link href="signout">
                <Button variant="secondary">
                  Sign Out
                </Button>
              </Nav.Link>
            )}
        </Nav> */}
      </Navbar.Collapse>
    </Navbar>
  );
}
