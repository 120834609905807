import axios from 'axios';
import { Storage } from 'aws-amplify';
import {
  loadSeedsInProgress,
  loadSeedArraySuccess,
} from './actions';

/* const baseURL = 'https://www.trueleafmarket.com/collections/micro-greens-planting-seed/products.json';
const axiosInstance = axios.create({
  baseURL,
}); */

// eslint-disable-next-line import/prefer-default-export
export const getSeedsArray = () => async (dispatch) => {
  // let tempArray = [];
  let seedsArray = [];
  dispatch(loadSeedsInProgress());
  const url = await Storage.get('seedsObject.json', { level: '/' });
  await axios.get(url, { headers: { Accept: 'application/json' } })
    .then((response) => {
      seedsArray = response.data;
    })
    .catch((error) => {
      console.log(error);
    });
  /*   for (let pages = 0; pages < 7; pages += 1) {
    // eslint-disable-next-line no-await-in-loop
    await axiosInstance
      .get('', {
        params: {
          page: pages,
          sort_by: 'title',
          sort_order: 'asc',
        },
      })
      .then((response) => {
        response.data.products.forEach((element) => {
          tempArray.push({
            id: element.id,
            title: element.title,
            handle: element.handle,
            variants: element.variants,
            images: element.images,
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const seedArray = tempArray.sort((obj1, obj2) => obj1.title.localeCompare(obj2.title));
  // Remove duplicates
  for (let i = 1; i < seedArray.length; i += 1) {
    if (seedArray[i].id === seedArray[i - 1].id) {
      seedArray.splice(i, 1);
    }
  }
  console.log(seedsArray); */
  // Below code downloads the seed object
  /* const uri = window.URL.createObjectURL(new Blob([JSON.stringify(seedArray)],
  { type: 'application/json' }));
  const link = document.createElement('a');
  link.href = uri;
  link.setAttribute('download', 'seedsObject');
  if (document.body != null) {
    document.body.appendChild(link);
  }
  link.click(); */
  dispatch(loadSeedArraySuccess(seedsArray));
};
