/* eslint-disable max-len */
import React, { useState } from 'react';
import {
  Button, Card, Col, Row,
} from 'react-bootstrap';
import styled from 'styled-components';
import BlogObject from './blogObject';
import MustardImage from '../mustard.png';
import FlavorfulImage from '../flavorful.png';
import RadishImage from '../Radish.png';

const ContainerStyle = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    text-align: left;
    .seedTitle {
        color: #990000;
        text-shadow: 2px 2px 5px grey;
    };
    .imgTitle {
      color: white;
      text-shadow: 2px 2px 5px grey;
      font-size: 26px;
      padding: 10px;
      text-align: center;
      -webkit-text-stroke: 1px rgb(64, 64, 64);
  };
    .imageContainer {
      border: 1px solid #a6a6a6;
      border-radius: 5px;
      padding: 1em;
      margin: 1em;
      background-image: linear-gradient(#f2f2f2, #e6e6e6);
      box-shadow: 5px 5px 5px grey;
  } 
`;

const Blog = () => {
  const [selectedBlog, setSelectedBlog] = useState('');
  const [hideBlog, setHideBlog] = useState(true);

  async function getBlog(value) {
    const blogValue = BlogObject.filter((blogs) => blogs.title === value);
    setSelectedBlog(blogValue[0]);
    setHideBlog(false);
  }
  return (
    <div className="calcForm">
      <ContainerStyle>
        <Row hidden={!hideBlog}>
          <Col className="imageContainer">
            <Card>
              <Card.Img variant="top" src={MustardImage} />
              <Card.Body>
                <Card.Title><h3>Mustard Microgreens</h3></Card.Title>
                <Card.Text>
                  Recently I decided to try out several different kinds of mustard microgreens to see what some of the key differences are.
                  During my trials, two things really stood out. One is that mustard can have many different flavors, from very spicy to mild to unique and earthy.
                  The second characteristic that stood out is the different colors. In this article I will share with you my experiences with the mustards I grew, the flavors, the appearance and potential uses.
                </Card.Text>
                <Button variant="primary" value="mustard" onClick={(e) => getBlog(e.target.value)}>Read more</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col className="imageContainer">
            <Card>
              <Card.Img variant="top" src={FlavorfulImage} />
              <Card.Body>
                <Card.Title><h3>Flavorful Microgreens</h3></Card.Title>
                <Card.Text>
                  With so many varieties of microgreens, you may be wondering which ones you will enjoy the most.
                  Many are surprised to find out they typically taste just like the their adult counterparts but sometimes with a more powerful flavor.
                  In this article we will create several flavor categories and discuss which microgreens belong there and why.
                </Card.Text>
                <Button variant="primary" value="flavorful" onClick={(e) => getBlog(e.target.value)}>Read more</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col className="imageContainer">
            <Card>
              <Card.Img variant="top" src={RadishImage} />
              <Card.Body>
                <Card.Title><h3>Radish Microgreens</h3></Card.Title>
                <Card.Text>
                  Radish microgreens are one of the most popular microgreens to grow and there are many reasons for that.
                  <p />
                  <ul>
                    <li>Super easy to grow</li>
                    <li>Big flavor for a small green</li>
                    <li>Many colors to choose from</li>
                    <li>So many uses (Salads, garnish, soups, sandwiches etc…)</li>
                  </ul>
                </Card.Text>
                <Button variant="primary" value="radish" onClick={(e) => getBlog(e.target.value)}>Read more</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <div hidden={hideBlog}>{selectedBlog.content}</div>
        <Button hidden={hideBlog} href="/blog">Back to Blogs</Button>
      </ContainerStyle>
    </div>
  );
};
export default Blog;
