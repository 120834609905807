import React, { useState } from 'react';
import {
  Accordion, Col, Row,
} from 'react-bootstrap';
import styled from 'styled-components';
import courseObject from './CourseObject';
import courseKit from './courseKit.png';
import { Event } from '../../Tracking';

export default function Course() {
  const CourseGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .courseTitle {
        color: rgb(255, 26, 26);
        text-shadow: 2px 2px 5px grey;
        font-size: 26px;
        padding: 10px;
        text-align: center;
        -webkit-text-stroke: 1px rgb(64, 64, 64);
    };
    .courseVideo {
      border: 1px solid #a6a6a6;
      border-radius: 5px;
      padding: 1em;
      margin: 1em;
      background-image: linear-gradient(#f2f2f2, #e6e6e6);
      box-shadow: 5px 5px 5px grey;
      justify-content: center;
      width: 800px;
      height: 450px;
    }
    .description {
      border-radius: 5px;
      text-align: left;
      background-color: white;
      justify-content: center;
      margin-left: 10%;
      margin-right: 10%;
      padding: 15px;
      font-weight: bold;
    }
    .courseContainer {
        border: 1px solid #a6a6a6;
        border-radius: 5px;
        padding: 1em;
        margin: 1em;
        background-image: linear-gradient(#f2f2f2, #e6e6e6);
        box-shadow: 5px 5px 5px grey;
    }
      .courseContainer:hover {
        background-color: #d9d9d9;
        animation-name: float;
        animation-duration: 2s;
        animation-iteration-count: 1; 
      }
    @keyframes float {
        0% {
          transform: translateY(0);
        }
        50% {
          transform: translateY(5px)
        }
        100% {
          transform: translateY(0px)
        }
      }
      @media only screen and (max-width: 1200Px) {
        .courseVideo {
          width: 700px;
          height: 395px;
        }
      }
      @media only screen and (max-width: 1000Px) {
        .courseVideo {
          width: 600px;
          height: 338px;
        }
      }
      @media only screen and (max-width: 800Px) {
        .courseVideo {
          width: 550px;
          height: 310px;
        }
      }
      @media only screen and (max-width: 600Px) {
        .courseVideo {
          width: 425px;
          height: 240px;
        }
      }
      @media only screen and (max-width: 500Px) {
        .courseVideo {
          width: 375px;
          height: auto;
        }
      }
      @media only screen and (max-width: 400Px) {
        .courseVideo {
          width: 325px;
          height: 211px;
        }
      }
      @media only screen and (max-width: 300Px) {
        .courseVideo {
          width: 250px;
          height: 141px;
        }
      }
`;
  const [largeVideo, setLargeVideo] = useState([]);
  const [hideVideos, setHideVideos] = useState(false);

  return (
    <div className="calcForm">
      <br />
      <p className="title">
        100% Free Microgreens Course
      </p>
      <Row style={{ justifyContent: 'center' }}>
        <Accordion style={{ width: '80%' }}>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              Course Materials
            </Accordion.Header>
            <Accordion.Collapse eventKey="0">
              <Accordion.Body>
                <CourseGrid>
                  <Row>
                    <Col className="courseContainer" onClick={() => Event('Course', 'Click', 'Active Grow Lights')}>
                      <p className="courseTitle">Active Grow LEDs</p>
                      <a alt="" target="_blank" rel="noopener noreferrer" onClick={() => Event('Course', 'Click', 'Active Grow Lights')} href="https://www.amazon.com/gp/product/B07K7XXHH8/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07K7XXHH8&linkCode=as2&tag=brabantfarm00-20&linkId=16ca450248110823e49d6daca2dcbe7e">
                        <img
                          alt=""
                          width="100%"
                          height="auto"
                          border="0"
                          src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07K7XXHH8&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=brabantfarm00-20"
                        />
                      </a>
                    </Col>
                    <Col className="courseContainer">
                      <p className="courseTitle">Deluxe grow kit</p>
                      <a
                        onClick={() => Event('Course', 'Click', 'Grow Kit')}
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.pjatr.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?sid=Master_growKit&website=300592&url=https%3A%2F%2Fwww.trueleafmarket.com%2Fcollections%2Fmicro-green-kits%2Fproducts%2Fdeluxe-microgreen-starter-kit%3Fvariant%3D40608952840"
                      >
                        <img
                          alt=""
                          width="100%"
                          height="ato"
                          src={courseKit}
                        />
                      </a>
                    </Col>
                  </Row>
                </CourseGrid>
              </Accordion.Body>
            </Accordion.Collapse>
          </Accordion.Item>
        </Accordion>
      </Row>
      <CourseGrid hidden={hideVideos}>
        {courseObject.map((video) => (
          <Row key={video.id}>
            <Col className="courseContainer" onClick={() => { setLargeVideo(video); setHideVideos(true); }}>
              {video.thumbnail}
              <h3 className="courseTitle">{video.title}</h3>
            </Col>
          </Row>
        ))}
      </CourseGrid>
      <CourseGrid hidden={!hideVideos}>
        <Row>
          <Col>
            {largeVideo.video}
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="description">
              {largeVideo.description}
            </div>
          </Col>
        </Row>
      </CourseGrid>
    </div>
  );
}
