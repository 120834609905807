import React from 'react';
import {
  Col, Row, Container, Ratio,
} from 'react-bootstrap';
import CourseImage from './courseImage.png';
import CalcImage from './calcImage.png';
import BlogImage from './blogs.png';
import Footer from './Footer';

const Dashboard = () => (
  <div>
    <Container style={{ justifyContent: 'center' }}>
      <Row>
        <Col>
          <p className="dashboardTitle" style={{ marginLeft: '10%', marginRight: '10%', textAlign: 'center' }}>Welcome to Microgreens Master!!</p>
          <Ratio aspectRatio="16x9">
            <iframe src="https://www.youtube.com/embed/P2K3o_PbMuA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
          </Ratio>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <a href="./calculator">
            <img alt="calcImg" width="100%" height="auto" src={CalcImage} />
          </a>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <a href="./course">
            <img alt="courseImg" href="./course" width="100%" height="auto" src={CourseImage} />
          </a>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <a href="./blog">
            <img alt="blogImg" href="./blog" width="100%" height="auto" src={BlogImage} />
          </a>
        </Col>
      </Row>
      <br />
      <Row>
        <Col style={{ background: 'rgb(91, 101, 112)' }}>
          <p className="text2" style={{ marginLeft: '10%', marginRight: '10%' }}>
            This website is still evolving rapidly,
            and is packed with information about growing microgreens.
          </p>
          <ul className="text2" style={{ marginLeft: '10%', marginRight: '10%' }}>
            <li>
              Use the seed calculator to find out exactly how much your seed
              costs are per gram/ounce of production of microgreens.
            </li>
            <li>
              Follow the microgreens course to learn everything you need to know to get growing!
            </li>
            <li>
              Read the blogs to learn details about different microgreen varieties and more...
            </li>
          </ul>
        </Col>
      </Row>
      <Footer />
    </Container>
    <br />
  </div>

);

export default Dashboard;
