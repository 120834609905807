import React from 'react';
import ReactDOM from 'react-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Provider } from 'react-redux';
import Amplify from 'aws-amplify';
import ReactGA from 'react-ga';
import { configureStore } from './app/store';
import App from './App';
import awsconfig from './aws-exports';

ReactGA.initialize('UA-147174898-1'); // add your tracking id here.
ReactGA.pageview(window.location.pathname + window.location.search);

Amplify.configure(awsconfig);

const store = configureStore();
const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate
      loading={<div>Loading...</div>}
      persistor={persistor}
    >
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
