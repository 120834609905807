import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  Row, Col, Image, Spinner,
} from 'react-bootstrap';
import { getSeedsArray } from './thunks';

const SeedGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .seedTitle {
        color: #990000;
        text-shadow: 2px 2px 5px grey;
        font-size: 22px;
        padding: 10px
    };
    .seedContainer {
        border: 1px solid #a6a6a6;
        border-radius: 5px;
        padding: 1em;
        margin: 1em;
        background-image: linear-gradient(#f2f2f2, #e6e6e6);
        box-shadow: 5px 5px 5px grey;
        max-width: 20em;
        height: 25em;
    }
    a:link {
        text-decoration: none;
      }
      a:hover {
        background-color: #d9d9d9;
        animation-name: float;
        animation-duration: 2s;
        animation-iteration-count: 1; 
      }
    @keyframes float {
        0% {
          transform: translateY(0);
        }
        50% {
          transform: translateY(5px)
        }
        100% {
          transform: translateY(0px)
        }
      } 
`;

const SeedsMap = ({ seeds = [], isLoading, startLoadingSeedsArray }) => {
  useEffect(() => {
    startLoadingSeedsArray();
  },
  [startLoadingSeedsArray]);
  const loadingMessage = (
    <div>
      <Spinner animation="border" size="lg" role="status">
        <span className="sr-only">Loading Seeds...</span>
      </Spinner>
      Loading Seeds, please wait
    </div>
  );
  const content = (
    <SeedGrid>
      {seeds.map((product) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.gopjn.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?url=https%3A%2F%2Fwww.trueleafmarket.com%2Fcollections%2Fmicro-greens-planting-seed%2Fproducts%2F${product.handle}%3Fvariant%3D${product.variants[0].id}`}
        >
          <Row key={product.id}>
            <Col className="seedContainer">
              <Image
                src={product.images[0].src}
                width="280em"
              />
              <h3 className="seedTitle">{product.title}</h3>
            </Col>
          </Row>
        </a>
      ))}
    </SeedGrid>
  );
  return isLoading ? loadingMessage : content;
  // return content;
};

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  seeds: state.seeds,
});

const mapDispatchToProps = (dispatch) => ({
  startLoadingSeedsArray: () => dispatch(getSeedsArray()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SeedsMap);
