async function convertToGrams(amountString) {
  const amount = amountString.toLowerCase();
  if (amount === '3 g packet') {
    return 3;
  }
  if (amount === '4 g packet') {
    return 4;
  }
  if (amount === '1/4 oz') {
    return 7.08;
  }
  if (amount === '25 g packet') {
    return 25;
  }
  if (amount === '1 oz') {
    return 28.35;
  }
  if (amount === '4 oz' || amount === '4 oz bag') {
    return 113.398;
  }
  if (amount === '8 oz' || amount === '8 oz bag') {
    return 226.79;
  }
  if (amount === '1 lb' || amount === '1 lb bag') {
    return 453.592;
  }
  if (amount === '2.5 lb' || amount === '2.5 lb bag') {
    return 1133.98;
  }
  if (amount === '5 lb' || amount === '5 lb can') {
    return 2267.96;
  }
  if (amount === '25 lb') {
    return 11339.8;
  }
  if (amount === '30 lb bucket') {
    return 13607.8;
  }
  return null;
}

export default convertToGrams;
