/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import TrueLeafAd from './TrueLeafAd.png';
import AmaranthSide from './AmaranthSide.png';
import TractorSupplyAd from './TractorSupplyAd.png';

const LeftSideAds = () => (
  <Col md="auto" className="sideAds">
    <Row>
      <Col>
        <a href="https://www.pntrac.com/t/SkNJT0lMSE1DR0xKS0ZJQ0dLSUdOSw?sid=microgreensmaster&website=300592" target="_blank" rel="sponsored"><img src={TrueLeafAd} border="0" width="120" height="450" title="Check out our Microgreens at True Leaf Market" alt="Check out our Microgreens at True Leaf Market" /></a>
      </Col>
    </Row>
    <br />
    <Row>
      <Col>
        <Image src={AmaranthSide} width="120px" />
      </Col>
    </Row>
    <br />
    <Row>
      <Col>
        <iframe title="cocoCoir" width="120px" height="240px" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=brabantfarm00-20&marketplace=amazon&amp;region=US&placement=B00DYCC3NS&asins=B00DYCC3NS&linkId=a6adc100b6e99519435246048f8de276&show_border=false&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff" />
      </Col>
    </Row>
    <br />
    <Row>
      <Col>
        <a href="https://www.gopjn.com/t/SkNJT0lGTktDR0xKS0ZJQ0dJTUZNTQ?sid=microgreensmaster&website=300592" target="_blank" rel="sponsored"><img src={TractorSupplyAd} border="0" width="120" height="600" title="Tractor Supply" alt="Tractor Supply" /></a>
      </Col>
    </Row>
  </Col>
);

export default LeftSideAds;
