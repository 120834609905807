/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Accordion, Container } from 'react-bootstrap';
import { Event } from '../../Tracking';
import { getSeedsArray } from '../seeds/thunks';
import PlantingList from './PlantingList';
import Details from '../Details';
import ComparisionCal from './ComparisionCal';
import convertToGrams from './ConvertToGrams';

const Calculator = ({
  seeds = [],
  startLoadingSeedsArray,
  isLoading,
  seedImage,
  setSeedImage,
}) => {
  // console.log(JSON.stringify(seeds[0]));
  const [sizes, setSizes] = useState([]);
  const [startSize, setStartSize] = useState('');
  const [grams, setGrams] = useState(0);
  const [price, setPrice] = useState(0);
  const [plantGrams, setPlantGrams] = useState(0);
  const [seedTrayCost, setSeedTrayCost] = useState(0);
  const [production, setProduction] = useState(0.00);
  const [microCost, setMicroCost] = useState(0);
  const [handle, setHandle] = useState('');
  const [link, setLink] = useState('');
  const [buttonVariety, setButtonVariety] = useState('');
  const [ouncesChecked, setOuncesChecked] = useState(false);
  const [hideCalcCompare, setHideCalcCompare] = useState(true);
  const firstRender = useRef(true);

  useEffect(() => {
    // useEffect is keeping the states up to date to avoid the delay.
    if (seeds.length <= 2 || firstRender.current) {
      startLoadingSeedsArray();
      firstRender.current = false;
    }
  }, [sizes,
    startSize,
    grams,
    price,
    plantGrams,
    seedTrayCost,
    production,
    microCost,
    handle,
    link,
    hideCalcCompare,
  ]);

  async function handleVarietyChange(e) {
    const variety = seeds.filter((varietys) => varietys.title === e.target.value);
    setSeedImage(variety[0].images[0].src);
    const myData = PlantingList.filter((details) => details.name === e.target.value);
    setButtonVariety(e.target.value);
    // for building the affiliate link
    const localHandle = variety[0].handle;
    setHandle(localHandle);
    const localId = variety[0].variants[0].id;
    const localLink = `https://www.pntra.com/t/TUJGRklGSkJGS0lKRUhCRkpIRk1K?sid=microgreensmaster&website=300592&url=https%3A%2F%2Fwww.trueleafmarket.com%2Fcollections%2Fmicro-greens-planting-seed%2Fproducts%2F${localHandle}%3Fvariant%3D${localId}`;
    setLink(localLink);
    const seedPrice = variety[0].variants[0].price;
    let gramsToPlant;
    let prod;
    try {
      gramsToPlant = myData[0].plant;
      prod = myData[0].production;
    } catch (error) {
      gramsToPlant = 0;
      prod = 0;
      console.log(error);
    }
    const localGrams = await convertToGrams(variety[0].variants[0].title);
    const trayCost = (seedPrice / (localGrams / gramsToPlant));
    setGrams(localGrams);
    setSizes(variety[0].variants);
    setStartSize(variety[0].variants[0].title);
    setPrice(seedPrice);
    setPlantGrams(gramsToPlant);
    setProduction(prod);
    setSeedTrayCost(trayCost);
    setMicroCost(trayCost / prod);
  }
  async function handleVariantChange(e) {
    const findVariant = sizes.filter((variant) => variant.title === e.target.value);
    setStartSize(e.target.value);
    const localTitle = e.target.value;
    const gram = await convertToGrams(localTitle);
    const pricing = findVariant[0].price;
    const trayCost = (pricing / (gram / plantGrams));
    setGrams(gram);
    setPrice(pricing);
    setSeedTrayCost(trayCost);
    setMicroCost(trayCost / production);
  }
  function updateProduction(e) {
    if (e !== undefined) {
      setMicroCost(seedTrayCost / e);
    } else { setMicroCost(seedTrayCost / production); }
  }
  function updatePlantingAmount(e) {
    if (e !== undefined) {
      setSeedTrayCost(price / (grams / e));
      setMicroCost((price / (grams / e) / production));
    } else {
      setSeedTrayCost(price / (grams / plantGrams));
      setMicroCost((price / (grams / plantGrams) / production));
    }
  }
  function handleOunces(e) {
    setOuncesChecked(e.target.checked);
  }
  function updatePrice(e) {
    const newPrice = e.split('$')[1];
    const newTrayCost = newPrice / (grams / plantGrams);
    setPrice(newPrice);
    setSeedTrayCost(newTrayCost);
    setMicroCost(newTrayCost / production);
  }

  function handleComparision() {
    if (hideCalcCompare === true) {
      setHideCalcCompare(false);
    } else {
      setHideCalcCompare(true);
    }
  }
  return (
    <Container className="calcForm">
      <br />
      <Row style={{ justifyContent: 'center' }}>
        <Accordion style={{ width: '80%' }}>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              About this Calculator
            </Accordion.Header>
            <Accordion.Body>
              <p className="text2" style={{ backgroundColor: 'rgb(139, 146, 155)', padding: '10px' }}>
                Use this handy calculator to determine the seed cost per gram/ounce of
                microgreens produced per tray. This calculator will help you determine
                the difference in cost from one variety
                to another. Enter your own planting weight and production amounts to get
                the most accurate costs. This information is one data point to help
                you understand how to price your microgreens.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
      <Form style={{ fontWeight: 'bold', color: 'rgb(255, 255, 255)', wordWrap: 'nowrap' }}>
        <Form.Label className="title">Microgreens Seed Calculator</Form.Label>
        <br />
        <Row>
          <Col lg>
            <Form.Group controlId="formGridVariety">
              <Form.Label>Seed Variety</Form.Label>
              <Form.Control as="select" defaultValue="Choose..." placeholder="Select a variety" onChange={(e) => handleVarietyChange(e)}>
                {isLoading && seeds.length <= 50
                  ? (
                    <option>
                      Loading, please wait
                    </option>
                  ) : <option>Choose a variety</option>}
                {seeds.map((variety) => (
                  <option key={variety.title}>{variety.title}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col lg>
            <Form.Group controlId="formGridUnits">
              <Form.Label>Seed Amount</Form.Label>
              <Form.Control as="select" placeholder="Choose units" value={startSize} onChange={(e) => handleVariantChange(e)}>
                {sizes.map((variant) => (
                  <option key={variant.title}>{variant.title}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col lg>
            <Form.Group as={Col} controlId="formGridPrice">
              <Form.Label>Seed Price</Form.Label>
              <Form.Control as="input" value={price ? (price.startsWith('$') ? price : `$${price}`) : '$'} onChange={(e) => updatePrice(e.target.value)} />
            </Form.Group>
          </Col>
        </Row>
        <br />
        <Row>
          <Col lg>
            <Form.Group controlId="formGridPlantWeight">
              <Form.Label>Dry Planting Weight (grams per 1020 tray)</Form.Label>
              <Form.Control as="input" value={plantGrams} onChange={(e) => { setPlantGrams(e.target.value); updatePlantingAmount(e.target.value); }} />
            </Form.Group>
          </Col>
          <Col lg>
            <Form.Group controlId="formGridCost">
              <Form.Label>Seed Cost per Tray</Form.Label>
              <Form.Control readOnly value={`$${seedTrayCost.toFixed(2)}`} />
            </Form.Group>
          </Col>
        </Row>
        <br />
        <Row>
          <Col lg>
            <Form.Group controlId="formGridProduction">
              <Form.Label>{`Expected Production per Tray (${ouncesChecked ? 'Ounces' : 'Grams'})`}</Form.Label>
              <Form.Control
                as="input"
                onClick={(e) => { e.target.value = ''; }}
                value={ouncesChecked ? (production / 28.3495).toFixed(0) : production}
                onChange={(e) => {
                  setProduction(ouncesChecked
                    ? (e.target.value * 28.3495) : e.target.value);
                  updateProduction(ouncesChecked
                    ? (e.target.value * 28.3495) : e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formGridCost">
              <Form.Label>{`Seed cost per ${ouncesChecked ? 'ounce' : 'gram'} of Microgreens`}</Form.Label>
              <Form.Control readOnly value={ouncesChecked ? `$${(microCost * 28.3495).toFixed(3)}` : `$${microCost.toFixed(3)}`} />
            </Form.Group>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Form.Group>
              <Form.Check type="checkbox" label="Show production in ounces" onChange={(e) => handleOunces(e)} />
            </Form.Group>
          </Col>
        </Row>
        <br />
        <Row>
          <Form.Group as={Col} controlId="purchaseButton">
            <Button variant="success" href={link} target="_blank" rel="sponsored" disabled={handle === ''} onClick={() => Event('Calculator', 'Purchase Button', buttonVariety)}>{`Purchase ${buttonVariety} Seeds`}</Button>
          </Form.Group>
        </Row>
        <br />
        <Row>
          <Form.Group as={Col} controlId="compareButton">
            <Button onClick={() => handleComparision()}>Compare Varieties</Button>
          </Form.Group>
        </Row>
      </Form>
      <br />
      <ComparisionCal hideCalcCompare={hideCalcCompare} />
      {seedImage !== '' ? <Details seedImage={seedImage} link={link} seedTitle={buttonVariety} /> : <div className="footer" />}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  seeds: state.seeds,
});

const mapDispatchToProps = (dispatch) => ({
  startLoadingSeedsArray: () => dispatch(getSeedsArray()),
});
Calculator.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  seeds: PropTypes.array.isRequired,
  startLoadingSeedsArray: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  seedImage: PropTypes.string.isRequired,
  setSeedImage: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, mapDispatchToProps)(Calculator);
