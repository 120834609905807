import {
  LOAD_SEED_ARRAY_SUCCESS,
  LOAD_SEEDS_IN_PROGRESS,
} from './actions';

const initialState = [
  {
    isLoading: false,
    seeds: [],
  },
];

export function isLoading(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case LOAD_SEEDS_IN_PROGRESS:
      return true;
    case LOAD_SEED_ARRAY_SUCCESS:
      return false;
    default:
      return state;
  }
}
export function seeds(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_SEED_ARRAY_SUCCESS: {
      // eslint-disable-next-line no-shadow
      const { seeds } = payload;
      return seeds;
    }
    case LOAD_SEEDS_IN_PROGRESS:
    default:
      return state;
  }
}
