import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Image, Row,
} from 'react-bootstrap';
import PlantingList from './calculator/PlantingList';

export default function Details(props) {
  const { seedImage, link, seedTitle } = props;
  const selectedSeed = PlantingList.filter((variety) => variety.name === seedTitle);
  try {
    return (
      <Container className="calcForm" hidden={seedImage === ''}>
        <Row>
          <Col className="detailsImage">
            {seedImage !== '' ? (
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src={seedImage}
                  width="100%"
                  style={{ alignContent: 'flex-start' }}
                />
              </a>
            ) : <div />}
          </Col>
          <Col>
            <h2 className="text">{seedTitle}</h2>
            <div className="blogText">
              <p>
                Flavor:&nbsp;
                {selectedSeed[0].flavor}
              </p>
              <p>
                Difficulty :&nbsp;
                {selectedSeed[0].difficulty }
              </p>
              <p>
                Soak:&nbsp;
                {selectedSeed[0].soak}
              </p>
              <p>
                Stack:&nbsp;
                {selectedSeed[0].stack}
              </p>
              <p>
                Harvest:&nbsp;
                {selectedSeed[0].harvestTime}
              </p>
              <p>
                Tip:&nbsp;
                {selectedSeed[0].tip}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  } catch (e) {
    console.log(e);
  }
}

Details.propTypes = {
  seedImage: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  seedTitle: PropTypes.string.isRequired,
};
